<div class="d-flex align-items-center justify-content-between mt-3">
    <div class="d-flex align-items-center">
        <span class="font-small-2 text-bold-500 cursor-pointer" [routerLink]="['/campaigns/overview']">{{'campaigns.title' | translate | uppercase}}</span>
        <span class="fa-regular font-small-2 fa-chevron-right mx-2"></span>
        <span class="font-small-2 text-bold-3 cursor-pointer" *ngIf="campaign" [routerLink]="['/campaigns/view', campaign.camp_id]">{{campaign.camp_name | uppercase}}</span>
    </div>
    <div class="d-flex">
        
    </div>
</div>
<div class="row mt-3" *ngIf="campaign">
    <div class="col-12">
        <div class="card">
            <div class="card-title">
                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-start px-3">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink><span class="font-small-3 text-bold-500">{{'campaigns.pages.campaignOverview' | translate}}</span></a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span class="text-bold-500">{{'campaigns.pages.generalCampaignInfo' | translate}}</span>
                                        <div class="btn btn-sm btn-primary p-1 px-3 font-small-2" [routerLink]="['/campaigns', campaign.camp_id]"><span class="fa-sharp fa-solid fa-pencil mr-2"></span>{{'campaigns.pages.editCampaign' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="campaign && customer">
                                    <div class="d-flex justify-content-start flex-wrap">
                                        <div class="d-flex pr-5">
                                            <div class="d-block overflow-hidden">
                                                <app-field [fieldname]="'camp_name'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                                <app-field [fieldname]="'camp_created'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                                <app-field [fieldname]="'camp_updated'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                            </div>
                                            <div class="d-block pl-3 overflow-hidden">
                                                <app-field [fieldname]="'camp_name'" [fieldvalue]="this.campaign.camp_name" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                                <app-field [fieldname]="'camp_created'" [fieldvalue]="this.campaign.camp_created" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                                <app-field [fieldname]="'camp_updated'" [fieldvalue]="this.campaign.camp_updated" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                            </div>
                                        </div>
                                        <div class="d-flex pr-5">
                                            <div class="d-block overflow-hidden">
                                                <app-field [fieldname]="'camp_start'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                                <app-field [fieldname]="'camp_end'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                                <app-field [fieldname]="'camp_state'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                            </div>
                                            <div class="d-block pl-3 overflow-hidden">
                                                <app-field [fieldname]="'camp_start'" [fieldvalue]="this.campaign.camp_start" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                                <app-field [fieldname]="'camp_end'" [fieldvalue]="this.campaign.camp_end" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                                <app-field [fieldname]="'camp_state'" [fieldvalue]="this.campaign.camp_state" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="d-block overflow-hidden">
                                                <app-field [fieldname]="'camp_customer'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                                <app-field [fieldname]="'cust_country'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                                <app-field [fieldname]="'cust_zip'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                                <app-field [fieldname]="'cust_place'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                                <app-field [fieldname]="'cust_street'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                            </div>
                                            <div class="d-block pl-3 overflow-hidden">
                                                <app-field [fieldname]="'camp_customer'" [fieldvalue]="this.campaign.camp_customer" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                                <app-field [fieldname]="'cust_country'" [fieldvalue]="this.customer.cust_country" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                                <app-field [fieldname]="'cust_zip'" [fieldvalue]="this.customer.cust_zip" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                                <app-field [fieldname]="'cust_place'" [fieldvalue]="this.customer.cust_place" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                                <app-field [fieldname]="'cust_street'" [fieldvalue]="this.customer.cust_street" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-5" *ngIf="products && campaign">
                                    <div class="row">
                                        <div class="col-6 col-md-3" *ngIf="campaign.camp_numberOfCoupns">
                                            <div class="card border border-light rounded-sm">
                                                <div class="card-header">
                                                    <span class="text-bold-500 font-medium-1">0 {{'campaigns.pages.binding' | translate}} {{campaign.camp_numberOfCoupns}}</span>
                                                </div>
                                                <div class="card-content">
                                                    <div class="card-body my-0 py-0">
                                                        <span class="text-muted text-bold-500 font-small-2">{{'campaigns.pages.conversions' | translate}}</span>
                                                    </div>
                                                </div>
                                                <div class="card-footer">
                                                    <ngb-progressbar type="primary" [value]="0" height=".5rem"></ngb-progressbar>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-3" *ngFor="let product of products">
                                            <div class="card border border-light rounded-sm" *ngIf="this.codes">
                                                <div class="card-header">
                                                    <span class="text-bold-500 font-medium-1">{{getProductCodesAmountRedeemed(product.prod_id)}} {{'campaigns.pages.binding' | translate}} {{getProductCodesAmount(product.prod_id)}}</span>
                                                </div>
                                                <div class="card-content">
                                                    <div class="card-body my-0 py-0">
                                                        <span class="text-muted text-bold-500 font-small-2">{{product.prod_name}}</span>
                                                    </div>
                                                </div>
                                                <div class="card-footer">
                                                    <ngb-progressbar type="primary" [value]="(getProductCodesAmountRedeemed(product.prod_id)/getProductCodesAmount(product.prod_id))*100" height=".5rem"></ngb-progressbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink><span class="font-small-3 text-bold-500">{{'campaigns.pages.coupnCodes' | translate}}</span></a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span class="text-bold-500">{{'coupns.pages.coupnGroups' | translate}}</span>
                                        <div class="btn btn-sm btn-primary p-1 px-3 font-small-2" [routerLink]="['/campaigns/view', campaign.camp_id, 'cpngrp', 'new']" ><span class="fa-sharp fa-solid fa-plus mr-2"></span>{{'coupns.pages.addNewCoupnGroup' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex justify-content-start flex-wrap">
                                        <div class="d-flex pr-5">
                                            <div class="d-block overflow-hidden font-medium-1 text-bold-500">
                                                <app-field [fieldname]="'cgrp_assigned'" [fieldDescription]="true" class="mt-2 font-small-3 lh-2"></app-field>
                                            </div>
                                            <div class="d-block pl-3 overflow-hidden">
                                                <app-field [fieldname]="'cgrp_assigned'" [slideroptions]="{max: campaign.camp_numberOfCoupns}" [fieldvalue]="this.assigned" class="mt-2 font-small-3 text-bold-500 lh-2"></app-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-5">
                                    <app-datatable [data]="this.coupngroups" [options]="this.datatableOptions_coupns" [events]="eventEmitters_coupns"></app-datatable>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink><span class="font-small-3 text-bold-500">{{'campaigns.pages.products' | translate}}</span></a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span class="text-bold-500">{{'campaigns.pages.productsInCampaign' | translate}}</span>
                                        <div class="btn btn-sm btn-primary p-1 px-3 font-small-2" [routerLink]="['/campaigns/view', campaign.camp_id, 'new']" ><span class="fa-sharp fa-solid fa-plus mr-2"></span>{{'campaigns.pages.addNewProduct' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <app-datatable [data]="this.products" [options]="this.datatableOptions" [events]="eventEmitters"></app-datatable>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink><span class="font-small-3 text-bold-500">{{'campaigns.pages.codesInCampaign' | translate}}</span></a>
                        <ng-template ngbNavContent>
                            <div class="row px-2">
                                <div class="col-12" >
                                    <div class="d-flex card border border-grey rounded-sm">
                                        <div class="card-body p-0">
                                            <div class="d-block border-bottom pb-3" *ngFor="let coupngrp of this.coupngroups">
                                                <div class="d-flex align-items-center lh-2 justify-content-between p-3">
                                                    <div class="d-flex justify-content-start">
                                                        <div class="d-flex text-bold-500 font-medium-1 mr-3" style="min-width: 12em">
                                                            {{coupngrp.cgrp_name}}
                                                        </div>
                                                        <div class="d-flex" *ngIf="!checkCoupnGroupFull(coupngrp)">
                                                            <app-field [fieldname]="'cgrp_assigned'" [slideroptions]="{max: coupngrp.cgrp_qty}" [fieldvalue]="getCodeAmounts(coupngrp.cgrp_id)" class="font-small-3 text-bold-500 lh-2"></app-field>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center lh-2 justify-content-end">
                                                        <div class="btn btn-sm btn-primary p-1 px-3 font-small-2" (click)="generateCodesToCoupngroup(coupngrp)" *ngIf="!checkCoupnGroupFull(coupngrp)">
                                                            <span class="fa-solid fa-shuffle mr-2"></span>
                                                            {{'campaigns.pages.generateCodes' | translate}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-block" *ngIf="checkCoupnGroupFull(coupngrp)">
                                                    <div class="d-block mx-3 px-3 py-3 bg-white rounded-sm mb-2" *ngFor="let product of products" >
                                                        <app-products-assign [campid]="campaign.camp_id" [product]="product" [cgrpid]="coupngrp.cgrp_id"></app-products-assign>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
</div>