import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgbdDatepickerI18n } from './datepicker-i18n';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgbModule, TranslateModule],
  declarations: [NgbdDatepickerI18n],
  exports: [NgbdDatepickerI18n],
  bootstrap: [NgbdDatepickerI18n]
})
export class NgbdDatepickerI18nModule {}
