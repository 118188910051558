import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { Coupngroup } from './coupngroup.service';

@Injectable({
  providedIn: 'root'
})

export class CoupncodeService implements OnDestroy{

    //Subscriptions
    coupncodeSub: Subscription;

    //collectiondef
    collection: string = "campaigns";
    subcollection: string = "coupns";

    constructor(
        private fs: AngularFirestore
        ) 
    { 
        
    }
    /** 
     * @param campaign_id id of the Campaign
     * @param id id of the Coupn 
     * @returns Coupn as Observable
    */
    getCoupncode(campaign_id: string, id: string) :Observable<any>{
        return this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection).doc(id).valueChanges();
    }

    /** 
     * @param campaign_id id of the Campaign
     * @returns AllCoupns Observable
    */
    getAllCoupncodes(campaign_id: string) :Observable<any>{
        return this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection , col => col.where('coup_state', '!=', 2)).valueChanges();
    }

    /** 
     * @param campaign_id id of the Campaign
     * @param coupngroup_id id of the Campaign
     * @returns AllCoupns Observable
    */
    getAllCoupncodesByGroup(campaign_id: string, coupngroup_id: string) :Observable<any>{
        return this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection , col => col.where('coup_state', '!=', 2).where('coup_group', '==', coupngroup_id)).valueChanges();
    }

    /** 
     * @param campaign_id id of the Campaign
     * @param coupngroup_id id of the Campaign
     * @param product_id id of the Campaign
     * @returns AllCoupns Observable
    */
    getAllProductCoupncodesByGroup(campaign_id: string, coupngroup_id: string, product_id: string) :Observable<any>{
        return this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection , col => col.where('coup_state', '!=', 2).where('coup_group', '==', coupngroup_id).where('coup_product', '==', product_id)).valueChanges();
    }

    /** 
     * @param campaign_id id of the Campaign
     * @param coupngroup_id id of the Campaign
     * @param product_id id of the Campaign
     * @returns AllCoupns Observable
    */
    getAllProductCoupncodes(campaign_id: string, product_id: string) :Observable<any>{
        return this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection , col => col.where('coup_state', '!=', 2).where('coup_product', '==', product_id)).valueChanges();
    }

    /** 
     * @param campaign_id id of the Campaign
     * @returns AllCoupncodes as Promise
    */
    getPromiseCoupncodes(campaign_id: string) :Promise<Coupn[]>{
        return new Promise<Coupn[]>((resolve) => {
            let sub = this.getAllCoupncodes(campaign_id).subscribe((sc) => {
                resolve(sc);
                if(sub){
                    sub.unsubscribe();
                }
            })
        })
    }

    /** 
     * @param campaign_id id of the Campaign
     * @returns True/False as Promise
    */
    getPromiseCheckforCodes(campaign_id: string) :Promise<boolean>{
        return new Promise<boolean>((resolve) => {
            this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection).get().toPromise().then((data) => {
                if(data.docs.length > 0){
                    resolve(true)
                }else{
                    resolve(false)
                }
            })
        })
    }


    ngOnDestroy(): void {

    }
}

export interface Coupn{
    coup_id: string,
    coup_product: string,
    coup_group: string,
    coup_state: number,
    coup_redeemed?: any,
    coup_delivered?: boolean,
    coup_redeemedInfo?: any,
    coup_created: any,
    coup_updated?: any,
}