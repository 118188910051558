import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { CampaignsRoutingModule } from "./campaigns-routing.module";

import { CampaignsComponent } from "./campaigns.component";
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CampaignOverviewComponent } from './campaign-overview/campaign-overview.component';
import { CampaignNewComponent } from './campaign-new/campaign-new.component';
import { CampaignEditComponent } from './campaign-edit/campaign-edit.component';
import { CampaignViewComponent } from './campaign-view/campaign-view.component';
import { ProductNewComponent } from './product-new/product-new.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { CoupngroupNewComponent } from './coupngroup-new/coupngroup-new.component';
import { CoupngroupEditComponent } from './coupngroup-edit/coupngroup-edit.component';
import { ProductAssignComponent } from './product-assign/product-assign.component';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    CampaignsRoutingModule
  ],
  exports: [
    TranslateModule
  ],
  declarations: [
    CampaignsComponent,
    CampaignOverviewComponent,
    CampaignNewComponent,
    CampaignEditComponent,
    CampaignViewComponent,
    ProductNewComponent,
    ProductEditComponent,
    CoupngroupNewComponent,
    CoupngroupEditComponent,
    ProductAssignComponent
  ],
  providers: [],
})
export class CampaignsModule { }
