    <div class="d-block" *ngIf="product_assignmentForm">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex" style="min-width: 70%;">
                <div class="d-flex lh3 font-small-3 text-bold-300" style="min-width: 30%;">
                    {{product.prod_name}}
                </div>
                <div class="d-flex" style="min-width: 70%;">
                    <app-field [fieldname]="'prod_assignment'" [simple]="true" [fieldvalue]="[value]" [slideroptions]="{min: 0, max: product.prod_qty, step: 1}" [form]="this.product_assignmentForm"></app-field>
                </div>
            </div>
            <div class="d-flex">
                <div class="btn btn-sm btn-outline-primary p-1 px-3 font-small-2" (click)="assignProducts()">
                    <span class="fa-sharp fa-solid fa-tag mr-2"></span>
                    {{'campaigns.pages.assignProducts' | translate}}
                </div>
            </div>
        </div>
    </div>