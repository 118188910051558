export const spinnerconfig: {
    type: string,
    size: any,
    bdColor: string,
    color: string,
    fullScreen: boolean
} = {
    type: 'ball-triangle-path',
    size: 'medium',
    bdColor: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    fullScreen: true
}

export const spinnerconfig_inline: {
    type: string,
    size: any,
    bdColor: string,
    color: string,
    fullScreen: boolean
} = {
    type: 'ball-triangle-path',
    size: 'small',
    bdColor: 'rgba(0, 0, 0, 0.0)',
    color: '#000',
    fullScreen: false
}

export const spinnerconfig_box: {
    type: string,
    size: any,
    bdColor: string,
    color: string,
    fullScreen: boolean
} = {
    type: 'ball-triangle-path',
    size: 'medium',
    bdColor: 'rgba(0, 0, 0, 0.5)',
    color: '#fff',
    fullScreen: false
}

export const spinnerconfig_box_white: {
    type: string,
    size: any,
    bdColor: string,
    color: string,
    fullScreen: boolean
} = {
    type: 'ball-triangle-path',
    size: 'medium',
    bdColor: 'rgba(255, 255, 255, 1)',
    color: '#000',
    fullScreen: false
}