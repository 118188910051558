import { Component, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CampaignService, Campaign } from '../../shared/services/campaigns.service';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss']
})

export class CampaignEditComponent {

  campaign_form: UntypedFormGroup;
  campaign_id: string;
  campaign_name: string;

  routeSub: Subscription;
  campSub: Subscription;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private campaignService: CampaignService,
    private cdr: ChangeDetectorRef
    ){
  }

  ngOnInit(): void {
    this.campaign_form = new UntypedFormGroup({});

    this.routeSub = this.activatedRoute.params.subscribe((par) => {
      if(par.id){
        this.campSub = this.campaignService.getCampaign(par.id).subscribe((campaign_data) => {
          this.campaign_id = campaign_data.camp_id;
          this.campaign_name = campaign_data.camp_name;
          this.campaign_form.patchValue(campaign_data);
          this.cdr.detectChanges();
        })
      }
    })
  }

  saveCampaign(){
    let new_Campaign = this.campaign_form.value;
    new_Campaign.camp_state = this.campaign_form.controls.camp_state.value == true ? 1 : 0;
    this.campaignService.saveCampaign(new_Campaign).then((result) => {
      this.router.navigate(['/campaigns/overview'])
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.routeSub) this.routeSub.unsubscribe();
    if(this.campSub) this.campSub.unsubscribe();
  }

}
