import { Injectable, OnDestroy } from '@angular/core';
import * as uuid from 'uuid'
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { ErrorService } from './error.service';
import { Country, countryListAllIsoData } from '../../../../functions/src/configs/country.configs';

@Injectable({
  providedIn: 'root'
})

export class CustomerService implements OnDestroy{
    //temporary NotificationObject
    customer: Customer;

    //Subscriptions
    customerSub: Subscription;

    //collectiondef
    collection: string = "customers";

    constructor(
        private fs: AngularFirestore,
        private errorservice: ErrorService
        ) 
    { 
        
    }
    /** 
     * @param id id of the Customer 
     * @returns Customer Observable
    */
    getCustomer(id) :Observable<any>{
        return this.fs.collection(this.collection).doc(id).valueChanges();
    }

    /** 
     * @returns AllCustomers Observable
    */
    getAllCustomers() :Observable<any>{
        return this.fs.collection(this.collection, col => col.where('cust_state', '!=', 2)).valueChanges();
    }

    /** 
     * @returns AllCustomers as Promise
    */
    getPromiseCustomers() :Promise<Customer[]>{
        return new Promise<Customer[]>((resolve) => {
            let sub = this.getAllCustomers().subscribe((sc) => {
                resolve(sc);
                if(sub){
                    sub.unsubscribe();
                }
            })
        })
    }

    /** 
     * @param sc Campaigns Object
     * @returns Promise of type Error
    */
    saveCustomer(sc: Customer, del?:boolean) :Promise<any>{
        return new Promise<any>((resolve) => {
            let document = this.fs.collection(this.collection);

            document.doc(sc.cust_id).get().toPromise().then((doc => {
                sc.cust_updated = new Date();
                if(!doc.exists){
                    sc.cust_id = uuid.v4();
                    sc.cust_created = new Date();
                    document.doc(sc.cust_id).set(sc).then((success) => {
                        this.errorservice.getError("ERR_000000004").then((error) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    }).catch((error) => {
                        this.errorservice.getError("ERR_000000005").then((error) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    });
                }else{
                    let success = "ERR_000000004";
                    let error = "ERR_000000005";
                    if(del){
                        sc.cust_state = 2;
                        success = "ERR_000000006";
                        error = "ERR_000000007"
                    }
                    document.doc(sc.cust_id).update(sc).then((s) => {
                        this.errorservice.getError(success).then((e) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    }).catch((er) => {
                        this.errorservice.getError(error).then((e) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    });
                }
            }))
        })
    }
    
    getPromiseCountries() :Promise<Country[]>{
        return new Promise<Country[]>((resolve) => {
                resolve(countryListAllIsoData);
        })
    }

    ngOnDestroy(): void {

    }
}

export interface Customer{
    cust_id: string,
    cust_name: string,
    cust_street?: string,
    cust_zip?: number,
    cust_place?: string,
    cust_country?: string,
    cust_contactId?: string,
    cust_contactName?: string,
    cust_contactEmail?: string,
    cust_contactPhone?: string
    cust_state: number,
    cust_created: any,
    cust_updated?: any,
}