import { Component, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignService, Campaign } from '../../shared/services/campaigns.service';
import { UntypedFormGroup } from '@angular/forms';


@Component({
  selector: 'app-campaign-new',
  templateUrl: './campaign-new.component.html',
  styleUrls: ['./campaign-new.component.scss']
})

export class CampaignNewComponent {

  campaign_form: UntypedFormGroup;

  constructor(private router: Router,
    private campaignService: CampaignService
    ){
  }

  ngOnInit(): void {
    this.campaign_form = new UntypedFormGroup({});
  }

  saveCampaign(){
    let new_Campaign = this.campaign_form.value;
    new_Campaign.camp_state = this.campaign_form.controls.camp_state.value == true ? 1 : 0;
    this.campaignService.saveCampaign(new_Campaign).then((result) => {
      this.router.navigate(['/campaigns/overview'])
    })
  }


}
