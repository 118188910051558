<div class="row mb-4" *ngIf="this.options?.search || this.options?.buttons">
  <div class="col-12 col-md-6 order-2 order-md-1 mt-md-0" *ngIf="this.options?.filter && this.options?.search">
    <form [formGroup]="searchForm">
      <div class="d-flex justify-content-start">
        <fieldset class="form-group position-relative has-icon-left mb-0">
          <input type="text" class="form-control" formControlName="search">
          <div class="form-control-position">
            <i class="fal fa-search"></i>
          </div>
        </fieldset>
      </div>
    </form>
  </div>
  <div class="col-12 order-1 order-md-2" [ngClass]="this.options?.filter ? ['col-md-6'] : ['col-md-12']" *ngIf="this.options?.buttons">
    <div class="btn d-none d-md-inline bg-secondary bg-lighten-4 align-items-center float-right ml-md-2 text-bold-500"><i class="fa fa-file-export font-small-2 mr-1"></i>Export</div>
    <div class="btn d-none d-md-inline bg-secondary bg-lighten-4 align-items-center float-right ml-md-2 text-bold-500"><i class="fa fa-file-import font-small-2 mr-1"></i>Import</div>
  </div>
</div>

  <div class="position-relative sc-overflow">
    <div class="d-flex px-1 font-small-2" *ngIf="dataDisplay" [ngStyle]="{'line-height': options?.classes?.header?.lineHeight, 'min-width': '100%'}">
      <div class="d-flex mw-50 text-primary text-bold-400 tx-overflow align-items-center" *ngIf="this.options?.reordering" [ngStyle]="{'width': '5%'}">
        <i class="fa fa-grip-vertical"></i>
      </div>
      <div class="text-bold-400 text-primary tx-overflow pr-4" [ngStyle]="{'width':getTableColumns(ind) + '%'}" [ngClass]="getClassListHeader(ind)" *ngFor="let dp of getDatapoints(dataDisplay[0]); let ind = index">
        <span class="cursor-pointer d-flex" (click)="sortTable(dp[0], getSortIcon(dp) == 'fa-sort-amount-down' ? 'fa-sort-amount-up' : 'fa-sort-amount-down')" *ngIf="getType(dp) == 'object'">
          <app-field [fieldname]="dp[0]" [fieldvalue]="dp[0]" [fieldDescription]="true"></app-field>
          <span *ngIf="this.actualSorting?.field == dp[0]"><i class="ml-4 fal" [ngClass]="getSortIcon(dp[0])"></i></span>
        </span>
        <span class="cursor-pointer d-flex" (click)="sortTable(dp, getSortIcon(dp) == 'fa-sort-amount-down' ? 'fa-sort-amount-up' : 'fa-sort-amount-down')" *ngIf="getType(dp) == 'string'">
          <app-field [fieldname]="dp" [fieldvalue]="dp" [fieldDescription]="true"></app-field>
          <span *ngIf="this.actualSorting?.field == dp"><i class="ml-4 fal" [ngClass]="getSortIcon(dp)"></i></span>
        </span>
        
      </div>
      <div class="d-flex mw-50" [ngStyle]="{'width': '10%'}"></div>
    </div>
    <div [dragula]='"tableRows"' [(dragulaModel)]="this.dataDisplay">
      <div class="col-12 px-1" *ngFor="let ds of dataDisplay" [ngStyle]="{'line-height': options?.classes?.row?.lineHeight, 'min-width': '100%'}">
        <div class="d-flex">
          <div class="d-flex tborder handle text-primary text-bold-400 justify-content-start mw-50 align-items-center cursor-pointer" *ngIf="this.options?.reordering" [ngStyle]="{'width': '5%'}">
            <i class="fa fa-grip-vertical handle"></i>
          </div>
          <div class="tx-overflow tborder" *ngFor="let dp of getDatapoints(ds); let ind = index" [ngClass]="getClassListRow(ind)" [ngStyle]="{'width': getTableColumns(ind) + '%'}">
            <span class="align-items-center flex-nowrap d-flex pr-4 mw-100" *ngIf="getType(dp) == 'object'">
              <span class="flex-nowrap d-flex align-items-center" *ngFor="let dpd of dp" ngFor="let dpd of dp">
                <input type="hidden" #eventdpd [value]="getEventClickable(dpd)">
                <app-field [fieldname]="dpd" [slideroptions]="{max: this?.options?.max ? this.options.max : 0}" [fieldvalue]="ds[dpd]" (click)="eventdpd?.value ? emitEvent(eventdpd.value, ds) : null" [ngClass]="eventdpd?.value ? ['cursor-pointer']: ['']"></app-field>
              </span>
            </span>
            <span class="flex-nowrap align-items-center d-flex pr-4 mw-100" *ngIf="getType(dp) == 'string'">
              <input type="hidden" #eventdp [value]="getEventClickable(dp)">
              <app-field [fieldname]="dp" [slideroptions]="{max: this?.options?.max ? this.options.max : 0}" [fieldvalue]="ds[dp]" (click)="eventdp?.value !== 'undefined' ? emitEvent(eventdp.value, ds) : null" [ngClass]="eventdp?.value !== 'undefined' ? ['cursor-pointer']: ['']"></app-field>
            </span>
          </div>
          <div class="d-flex justify-content-end mw-50 tborder" *ngIf="this.options.actions" [ngStyle]="{'width': '10%'}">
            <span class="mr-2" container="body" [ngbPopover]="action.title | translate" placement="top-right" triggers="mouseenter:mouseleave" *ngFor="let action of this.options.actions" (click)="emitEvent(action.id, ds)"><div class="btn bg-secondary text-primary bg-lighten-4 btn-sm"><i [ngClass]="action.icon" *ngIf="action.icon"></i></div></span>
          </div>
        </div>
      </div>
    </div>
  </div>







