import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CampaignsComponent } from './campaigns.component';
import { CampaignOverviewComponent } from './campaign-overview/campaign-overview.component';
import { CampaignNewComponent } from './campaign-new/campaign-new.component';
import { CampaignEditComponent } from './campaign-edit/campaign-edit.component';
import { CampaignViewComponent } from './campaign-view/campaign-view.component';
import { ProductNewComponent } from './product-new/product-new.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { CoupngroupNewComponent } from './coupngroup-new/coupngroup-new.component';
import { CoupngroupEditComponent } from './coupngroup-edit/coupngroup-edit.component';


const routes: Routes = [
  {
    path: '',
    component: CampaignsComponent,
    data: {
      title: 'Campaigns Overview'
    },
    children: [
      {
        path: 'overview',
        component: CampaignOverviewComponent,
        data: {
          title: 'Campaign Overview'
        }
      },
      {
        path: 'new',
        component: CampaignNewComponent,
        data: {
          title: 'New Campaign'
        }
      },
      {
        path: ':id',
        component: CampaignEditComponent,
        data: {
          title: 'Edit Campaign'
        }
      },
      {
        path: 'view/:id',
        component: CampaignViewComponent,
        data: {
          title: 'View Campaign'
        }
      },
      {
        path: 'view/:campaign_id/new',
        component: ProductNewComponent,
        data: {
          title: 'New Product'
        }
      },
      {
        path: 'view/:campaign_id/:product_id',
        component: ProductEditComponent,
        data: {
          title: 'Edit Product'
        }
      },
      {
        path: 'view/:campaign_id/cpngrp/new',
        component: CoupngroupNewComponent,
        data: {
          title: 'New Coupngroup'
        }
      },
      {
        path: 'view/:campaign_id/cpngrp/:coupngroup_id',
        component: CoupngroupEditComponent,
        data: {
          title: 'Edit Coupngroup'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CampaignsRoutingModule { }
