import { Injectable }  from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
 
@Injectable()
export class AppInitService {
    //Injects AuthService in App initialization and waits for Obeservable to fire
    //so it will be loaded for sure on each site Request.
    constructor(
            private authservice: AuthService,
            private router: Router
        ) {
    }
    
    Init() {
        return this.authservice.isAuthenticated()
    }
}