import { Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { findError, severity } from '../../../../functions/src/configs/errors.configs';
import { CustomError, CustomErrorLog } from '../../../../functions/src/interfaces/error.interface';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})

export class ErrorService{
    //temporary ErrorObject
    error: CustomError;

    constructor(
        private toastr: ToastrService,
        private translate: TranslateService
        ) 
    { 
    }

    /** 
     * @param id Unique Error ID from Config-File
     * @param replacement Data-Binding Replacement for ErrorMessage     
     * @param object CustomErrorObject for Storing additional Informations to the Error
     * Fetch Error from ConfigFile (by Error Number) and Translate the Message with TranslateService
     * In the ConfigFile each error is able to replace the [data] placeholder with an replacement-Text
     * Additionally if object is set -> Additional Error Object informations can be stored
     * @returns a new Promise, that resolves the translated ErrorObject
    */
    getError(id: string , replacement?: string, object?: object, loggingDisabled? :boolean) :Promise<boolean>{
        return new Promise<boolean>((resolve) => {
            this.error = findError(id, object);
            this.translate.get(this.error.message).toPromise().then((translated :string) => {
                this.error.message = translated.replace('[data]', replacement);
                resolve(true);
            });
        })
    }

    /** 
     * Errorhandling -> Pops Toast in Frontend with the right Settings comeing form Error itself
     * @returns the ActiveToastReference for binding additional Events
    */
    popToast() :ActiveToast<any>{

        if(this.error.severity == severity[0]){
        //success
            return this.toastr.success(this.error.message);
        }else if(this.error.severity == severity[1]){
        //info
            return this.toastr.info(this.error.message);
        }else if(this.error.severity == severity[2]){
        //warning
            return this.toastr.warning(this.error.message);
        }else{
        //danger
            return this.toastr.error(this.error.message);
        }
    }

    /** 
     * @param error Any Object
     * Checks if the given inputParameter is type of Custom Error
     * @returns true || false 
    */
    isTypeOfError(error :any) :error is CustomError{
        if(error.severity && error.id && error.timestamp){
            return true;
        }else{
            return false;
        }
    }
}
