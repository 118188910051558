import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'campaigns',
    loadChildren: () => import('../../campaigns/campaigns.module').then(m => m.CampaignsModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('../../customers/customers.module').then(m => m.CustomersModule)
  }
];
