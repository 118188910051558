import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/campaigns/overview', title: 'Campaigns', icon: 'fa-light fa-envelope', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/customers/overview', title: 'Customers', icon: 'fa-light fa-person', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/settings', title: 'Settings', icon: 'fa-light fa-gear', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  }
];
