import { Injectable, OnDestroy } from '@angular/core';
import * as uuid from 'uuid'
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { ErrorService } from './error.service';
import { Country, countryListAllIsoData } from '../../../../functions/src/configs/country.configs';

@Injectable({
  providedIn: 'root'
})

export class ProductService implements OnDestroy{
    //temporary NotificationObject
    product: Product;

    //Subscriptions
    productSub: Subscription;

    //collectiondef
    collection: string = "campaigns";
    subcollection: string = "products";

    constructor(
        private fs: AngularFirestore,
        private errorservice: ErrorService
        ) 
    { 
        
    }
    /** 
     * @param campaign_id id of the Campaign
     * @param id id of the Product 
     * @returns Product as Observable
    */
    getProduct(campaign_id: string, id: string) :Observable<any>{
        return this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection).doc(id).valueChanges();
    }

    /** 
     * @param campaign_id id of the Campaign
     * @returns AllProducts Observable
    */
    getAllProducts(campaign_id: string) :Observable<any>{
        return this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection, col => col.where('prod_state', '!=', 2)).valueChanges();
    }

    /** 
     * @param campaign_id id of the Campaign
     * @returns AllProducts as Promise
    */
    getPromiseProducts(campaign_id: string) :Promise<Product[]>{
        return new Promise<Product[]>((resolve) => {
            let sub = this.getAllProducts(campaign_id).subscribe((sc) => {
                resolve(sc);
                if(sub){
                    sub.unsubscribe();
                }
            })
        })
    }

    /** 
     * @param sc Product Object
     * @param campaign_id id of the Campaign
     * @returns Promise of type Error
    */
    saveProduct(sc: Product, campaign_id: string, del?:boolean) :Promise<any>{
        return new Promise<any>((resolve) => {
            let document = this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection);

            document.doc(sc.prod_id).get().toPromise().then((doc => {
                sc.prod_updated = new Date();
                if(!doc.exists){
                    sc.prod_id = uuid.v4();
                    sc.prod_created = new Date();
                    document.doc(sc.prod_id).set(sc).then((success) => {
                        this.errorservice.getError("ERR_000000008").then((error) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    }).catch((error) => {
                        this.errorservice.getError("ERR_000000009").then((error) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    });
                }else{
                    let success = "ERR_000000008";
                    let error = "ERR_000000009";
                    if(del){
                        sc.prod_state = 2;
                        success = "ERR_000000010";
                        error = "ERR_000000011"
                    }
                    document.doc(sc.prod_id).update(sc).then((s) => {
                        this.errorservice.getError(success).then((e) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    }).catch((er) => {
                        this.errorservice.getError(error).then((e) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    });
                }
            }))
        })
    }
    
    getPromiseCountries() :Promise<Country[]>{
        return new Promise<Country[]>((resolve) => {
                resolve(countryListAllIsoData);
        })
    }

    ngOnDestroy(): void {

    }
}

export interface Product{
    prod_id: string,
    prod_name: string,
    prod_qty?: number,
    prod_desc?: string,
    prod_state: number,
    prod_isConnexProd?: boolean,
    prod_extArtnNumber?: string,
    prod_created: any,
    prod_updated?: any,
}