import { Injectable, OnDestroy } from '@angular/core';
import * as uuid from 'uuid'
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { ErrorService } from './error.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class CampaignService implements OnDestroy{
    //temporary NotificationObject
    serviceCategory: Campaign;

    //Subscriptions
    serviceCategorySub: Subscription;

    //collectiondef
    collection: string = "campaigns";
    categoryCollection: string = "servicecategories";

    constructor(
        private fs: AngularFirestore,
        private errorservice: ErrorService
        ) 
    { 
        
    }
    /** 
     * @param id id of the Campaign 
     * @returns Campagin Observable
    */
    getCampaign(id) :Observable<any>{
        return this.fs.collection(this.collection).doc(id).valueChanges();
    }

    /** 
     * @returns AllCampaigns Observable
    */
    getAllCampaigns() :Observable<any>{
        return this.fs.collection(this.collection, col => col.where('camp_state', '!=', 2)).valueChanges();
    }

    /** 
     * @returns AllCampaigns as Promise
    */
    getPromiseCampaigns() :Promise<Campaign[]>{
        return new Promise<Campaign[]>((resolve) => {
            let sub = this.getAllCampaigns().subscribe((sc) => {
                resolve(sc);
                if(sub){
                    sub.unsubscribe();
                }
            })
        })
    }

    /** 
     * @param sc Campaigns Object
     * @returns Promise of type Error
    */
    saveCampaign(sc: Campaign, del?:boolean) :Promise<any>{
        return new Promise<any>((resolve) => {

            let document = this.fs.collection(this.collection);

            document.doc(sc.camp_id).get().toPromise().then((doc => {
                sc.camp_updated = new Date();
                if(!doc.exists){
                    sc.camp_id = uuid.v4();
                    sc.camp_created = new Date();
                    document.doc(sc.camp_id).set(sc).then((success) => {
                        this.errorservice.getError("ERR_000000000").then((error) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    }).catch((error) => {
                        this.errorservice.getError("ERR_000000001").then((error) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    });
                }else{
                    let success = "ERR_000000000";
                    let error = "ERR_000000001"
                    if(del){
                        sc.camp_state = 2;
                        success = "ERR_000000002";
                        error = "ERR_000000003"
                    }
                    document.doc(sc.camp_id).update(sc).then((s) => {
                        this.errorservice.getError(success).then((e) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    }).catch((er) => {
                        this.errorservice.getError(error).then((e) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    });
                }
            }))
        })
    }
    
    ngOnDestroy(): void {

    }
}

export interface Campaign{
    camp_id: string,
    camp_name: string,
    camp_customer: string,
    camp_start: any,
    camp_end: any,
    camp_domainLp?: string,
    camp_numberOfCoupns?: number,
    camp_coupnPrefixes?: string,
    camp_suffixes?: string,
    camp_state: number,
    camp_created: any,
    camp_updated?: any,
}