<span *ngIf="(this.isField == 'text' || this.isField == 'textarea' || this.isField == 'time' || this.isField == 'order' || this.isField == 'number') && !this.fieldDescription && !this.form" class="field_text text-nowrap" #fieldtext container="body" [ngbPopover]="this.value" triggers="mouseenter:mouseleave" [disablePopover]="popdisabled">{{this.value | translate}} <span *ngIf="this.options?.labeling">{{this.options?.labeling | translate}}</span></span>
<span *ngIf="this.isField == 'currency' && !this.fieldDescription && !this.form" class="field_text text-nowrap" #fieldtext container="body" [ngbPopover]="this.value" triggers="mouseenter:mouseleave" [disablePopover]="popdisabled">{{this.value.toLocaleString(undefined, {  minimumFractionDigits: 2,  maximumFractionDigits: 2,  style: "currency", currency: "EUR", useGrouping:true})}} <span *ngIf="this.options?.labeling">{{this.options?.labeling | translate}}</span></span>
<span *ngIf="this.isField == 'icon' && !this.fieldDescription && !this.form" class="field_icon text-nowrap" #fieldicon container="body" [ngbPopover]="this.value" triggers="mouseenter:mouseleave" [disablePopover]="popdisabled"><i [ngClass]="[this.value]" ></i></span>
<span *ngIf="this.isField == 'date' && !this.fieldDescription && !this.form" class="field_date text-nowrap" #fielddate container="body" [ngbPopover]="this.value" triggers="mouseenter:mouseleave" [disablePopover]="popdisabled">{{this.value}}</span>
<span *ngIf="this.isField == 'sum' && !this.fieldDescription && !this.form" class="field_date text-nowrap" #fieldcount container="body" [ngbPopover]="this.value" triggers="mouseenter:mouseleave" [disablePopover]="popdisabled" class="position-relative d-flex"><span style="width:100%" [countUp]="this.value">0</span></span>
<span *ngIf="this.fieldDescription && !this.form && this.isField !== 'serviceclass'" class="field_heading text-nowrap field_text" #fieldheading container="body" [ngbPopover]="this.value" triggers="mouseenter:mouseleave" [disablePopover]="popdisabled">{{'Fields.'+this.fieldname | translate}}</span>
<span *ngIf="this.fieldDescription && !this.form && this.isField == 'serviceclass'" class="field_heading text-nowrap field_text" #fieldheading container="body" [ngbPopover]="this.value" triggers="mouseenter:mouseleave" [disablePopover]="popdisabled">{{this.value}}</span>
<span *ngIf="this.isField == 'slider' && !this.fieldDescription && !this.form" class="field_text text-nowrap" #fieldtext><span class="d-flex align-items-center"><ngb-progressbar type="primary" [value]="this.value" [max]="slideroptions?.max ? slideroptions.max : 100" height="1rem" style="min-width: 12em"><span class="font-small-1"></span></ngb-progressbar><span class="text-bold-500 font-small-3 ml-2">{{this.value}} / {{slideroptions?.max ? slideroptions.max : 100}}</span></span></span>


<span *ngIf="(this.isField == 'toggle') && !this.fieldDescription && !this.form" class="field_text text-nowrap ">
  <span class="d-flex align-items-center badge badge-pill px-2 field_text bg-lighten-3" [ngClass]="this.value == 1 ? ['bg-success'] : ['bg-danger']">
    <span *ngIf="this.value == 1" class="text-bold-500 field_text d-flex text-success"><span class="fa-solid fa-circle-small text-success mr-1"></span>{{'Fields.'+this.fieldname+'_label_on' | translate}}</span>
    <span *ngIf="this.value == 0" class="text-bold-500 field_text d-flex text-danger"><span class="fa-solid fa-circle-small text-danger mr-1"></span>{{'Fields.'+this.fieldname+'_label_off' | translate}}</span>
  </span>
</span>

<span *ngIf="(this.isField == 'select') && !this.fieldDescription && !this.form" class="field_text text-nowrap position-relative"
#fieldtext container="body" [ngbPopover]="this.value" triggers="mouseenter:mouseleave" [disablePopover]="popdisabled">
  <ngx-spinner *ngIf="!this.value"></ngx-spinner>{{this.value}}
</span>

<span *ngIf="(this.isField == 'period') && !this.fieldDescription && !this.form" class="field_text text-nowrap position-relative">
  <ngx-spinner *ngIf="!this.value"></ngx-spinner>{{this.value | translate}}
</span>

<form [formGroup]="this.form" *ngIf="this.form">
<!-- Input Definitions Textbox-->
<fieldset *ngIf="this.form && this.isField == 'text' && this.formControlName" class="input_field_heading form-group">
  <label for="{{this.fieldname}}" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['text-danger'] : ['']">{{'Fields.'+this.fieldname | translate}}</label>
  <input type="text" autocomplete="off" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['border-danger', 'border'] : ['']" [formControlName]="this.formControlName" id="{{this.fieldname}}" placeholder="{{'Fields.'+this.fieldname+'_placeholder' | translate}}" class="form-control">
</fieldset>

<!-- Input Definitions TextArea-->
<fieldset *ngIf="this.form && this.isField == 'textarea' && this.formControlName" class="input_field_heading form-group">
  <label for="{{this.fieldname}}" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['text-danger'] : ['']">{{'Fields.'+this.fieldname | translate}}</label>
  <textarea type="textarea"  autocomplete="off" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['border-danger', 'border'] : ['']" [rows]="5" [formControlName]="this.formControlName" id="{{this.fieldname}}" placeholder="{{'Fields.'+this.fieldname+'_placeholder' | translate}}" class="form-control"></textarea>
</fieldset>

<!-- Input Definitions Select-->
<fieldset *ngIf="this.form && this.isField == 'select' && this.formControlName" class="input_field_heading form-group">
  <label for="{{this.fieldname}}" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['text-danger'] : ['']">{{'Fields.'+this.fieldname | translate}}</label>
  <ng-select class="rounded-0" id="{{this.fieldname}}" [bindLabel]="this.options?.displayfield.substr(0,4) + '_name'" [bindValue]="this.options?.displayfield.substr(0,4) + '_id'" [items]="this.items" [formControlName]="this.formControlName" placeholder="{{'Fields.'+this.fieldname+'_placeholder' | translate}}"  [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['danger'] : ['']">
    <ng-template ng-label-tmp let-item="item">
      {{item[this.options?.displayfield]}}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
      {{item[this.options?.displayfield]}}
    </ng-template>
  </ng-select>
</fieldset>

<!-- Input Definitions Currency-->
<div *ngIf="this.form && this.isField == 'currency' && this.formControlName" >
  <label for="{{this.fieldname}}" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['text-danger'] : ['']">{{'Fields.'+this.fieldname | translate}}</label>
  <fieldset class="input_field_heading form-group position-relative has-icon-left">
    <input type="number" step="0.01" min="0" lang="de" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['border-danger', 'border'] : ['']" [formControlName]="this.formControlName" id="{{this.fieldname}}" placeholder="{{'Fields.'+this.fieldname+'_placeholder' | translate}}" class="form-control">
    <div class="form-control-position" ngStyle="width: {{this.options?.size}}"><i class="fa fa-euro-sign"></i></div>
  </fieldset>
</div>

<!-- Input Definitions Number-->
<div *ngIf="this.form && this.isField == 'number' && this.formControlName" >
  <label *ngIf="this.simple == undefined || this.simple == false" for="{{this.fieldname}}" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['text-danger'] : ['']">{{'Fields.'+this.fieldname | translate}}</label>
  <fieldset class="input_field_heading position-relative has-icon-right" [ngClass]="this.simple == undefined || this.simple == false ? 'form-group' : ''" >
    <input type="number" step="1" min="0" lang="de" [ngStyle]="{'padding-right': this.options?.size}" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['border-danger', 'border'] : ['']" [formControlName]="this.formControlName" id="{{this.fieldname}}" placeholder="{{'Fields.'+this.fieldname+'_placeholder' | translate}}" class="form-control">
    <div class="form-control-position text-bold-300 text-primary font-small-2" *ngIf="this.options?.labeling" [ngStyle]="{'width': this.options?.size}">{{this.options?.labeling | translate}}</div>
  </fieldset>
</div>

<!-- Input Definitions UI-Slider-->
<div *ngIf="this.form && this.isField == 'slider' && this.formControlName && slideroptions" >
  <label *ngIf="this.simple == undefined || this.simple == false" class="mb-4" for="{{this.fieldname}}" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['text-danger'] : ['']">{{'Fields.'+this.fieldname | translate}}</label>
  <fieldset class="input_field_heading position-relative has-icon-right" [ngClass]="this.simple == undefined || this.simple == false ? 'form-group' : ''" >
    <div class="d-flex align-items-center" style="width: 100%;">
      <input class="form-control pr-0" type="number" style="width: 20%" [formControlName]="this.formControlName">
      <nouislider style="width: 75%; margin-left: 5%" [connect]="'lower'" [tooltips]="true" [min]="slideroptions.min" [max]="slideroptions.max" [step]="slideroptions.step" [formControl]="form.get(this.formControlName)"  [disabled]="disabled">Success</nouislider>
    </div>
    <div class="form-control-position text-bold-300 text-primary font-small-2" *ngIf="this.options?.labeling" [ngStyle]="{'width': this.options?.size}">{{this.options?.labeling | translate}}</div>
  </fieldset>
</div>

<!-- Input Definitions Date-->
<fieldset *ngIf="this.form && this.isField == 'date' && this.formControlName" class="input_field_heading form-group">
  <label for="{{this.fieldname}}" [ngClass]="this.form.controls[this.fieldname].dirty && this.form?.controls[this.fieldname]?.errors ? ['text-danger'] : ['']">{{'Fields.'+this.fieldname | translate}}</label>
  <ngbd-datepicker-i18n [form]="this.form" [fieldname]="this.fieldname" [Inline]="false" [selectedDate]="this.fieldvalue" #autofocus></ngbd-datepicker-i18n>
</fieldset>

<!-- Input Definitions Toggle-->
<div *ngIf="this.form && this.isField == 'toggle' && this.formControlName" >
  <fieldset class="input_field_heading" [ngClass]="this.simple == undefined || this.simple == false ? 'form-group' : ''">
    <label for="{{this.fieldname}}" *ngIf="this.simple == undefined || this.simple == false" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['text-danger'] : ['']">
      {{'Fields.'+this.fieldname | translate}}
    </label>
    <span class="d-flex align-items-center">
      <ui-switch size="small" color="#FFF500" [ngClass]="this.form.controls[this.fieldname].touched && this.form?.controls[this.fieldname]?.errors ? ['border-danger', 'border'] : ['']" [formControlName]="this.formControlName" [formControlName]="this.formControlName"></ui-switch>
      <span class="d-flex mt-1 ml-2 font-small-2">
        <span *ngIf="this.form.controls[this.fieldname].value">{{'Fields.'+this.fieldname+'_label_on' | translate}}</span>
        <span *ngIf="!this.form.controls[this.fieldname].value">{{'Fields.'+this.fieldname+'_label_off' | translate}}</span>
      </span>
    </span>
  </fieldset>
</div>


</form>


