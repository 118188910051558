import { Component, EventEmitter } from '@angular/core';
import { DatatableOptions } from '../../shared/components/datatable/datatable.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CampaignService, Campaign } from '../../shared/services/campaigns.service';
import { AuthService } from '../../shared/auth/auth.service';


@Component({
  selector: 'app-campaign-overview',
  templateUrl: './campaign-overview.component.html',
  styleUrls: ['./campaign-overview.component.scss']
})

export class CampaignOverviewComponent {

  eventEmitters : EventEmitter<any>[] = new Array();
  datatableOptions: DatatableOptions;
  deleteElement: Subscription;
  clickElement: Subscription;
  editElement: Subscription;
  campaginsSub: Subscription;
  data;

  constructor(private router: Router,
    private campaignService: CampaignService){
  }

  ngOnInit(): void {

    this.campaginsSub = this.campaignService.getAllCampaigns().subscribe((camps :Campaign[]) => {
      this.data = camps;
    })

    this.datatableOptions = {
      classes: {
        header: {
          lineHeight: "2em",
          classes: [
            ['mw-125', 'd-flex'], 
            ['mw-100', 'd-none', 'd-sm-flex'], 
            ['mw-50', 'd-none', 'd-sm-flex'], 
            ['mw-50', 'd-none', 'd-lg-flex', 'justify-content-end'], 
            ['mw-50', 'd-none', 'd-lg-flex', 'justify-content-end'], 
            ['mw-50', 'd-none', 'd-lg-flex', 'justify-content-end'], 
          ],
          counts: {
            xs: {items:1 , widths: [100]},
            sm: {items:3 , widths: [40, 40, 20]},
            md: {items:3 , widths: [40, 40, 20]},
            lg: {items:6 , widths: [25, 25, 12.5, 12.5, 12.5, 12.5]},
            xl: {items:6 , widths: [25, 25, 12.5, 12.5, 12.5, 12.5]}
          }
        },
        row: {
          lineHeight: "4em",
          classes: [
            ['text-bold-500', 'mw-125', 'd-flex', 'font-small-2'], 
            ['mw-100', 'd-none', 'd-sm-flex', 'font-small-2'], 
            ['mw-50', 'd-none', 'd-sm-flex', 'font-small-2'], 
            ['mw-50', 'd-none', 'd-lg-flex', 'font-small-2', 'justify-content-end', 'text-right'], 
            ['mw-50', 'd-none', 'd-lg-flex', 'font-small-2', 'justify-content-end', 'text-right'], 
            ['mw-50', 'd-none', 'd-lg-flex', 'font-small-2', 'justify-content-end', 'text-right'], 
          ]
        }
      },
      fields: ['camp_name', 'camp_customer' ,'camp_state', 'camp_numberOfCoupns', 'camp_created', 'camp_updated'],
      actions: [
        {id: "edit", title: "campaigns.pages.editCampaign", icon: "fa-sharp fa-solid fa-pencil"},
        {id: "delete", title: "campaigns.pages.removeCampaign", icon: "fa-sharp fa-solid fa-trash"}
      ],
      click: [{
        name: 'camp_name',
        event: 'clickRow'
      }],
      filter: ['camp_name'],
      search: true
    }

    this.datatableOptions?.actions?.forEach(action => {
      let event = new EventEmitter();
      this.eventEmitters[action.id] = event;
    });

    this.eventEmitters['clickRow'] = new EventEmitter();

    this.deleteElement = this.eventEmitters['delete'].subscribe((data) => {
      this.campaignService.saveCampaign(data, true);
    })

    this.editElement = this.eventEmitters['edit'].subscribe((data) => {
      this.router.navigate(['/campaigns',data.camp_id]);
    })

    this.clickElement = this.eventEmitters['clickRow'].subscribe((data :any) => {
      this.router.navigate(['/campaigns/view',data.camp_id]);
    })
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.deleteElement) this.deleteElement.unsubscribe();
    if(this.editElement) this.editElement.unsubscribe();
    if(this.clickElement) this.clickElement.unsubscribe();
    if(this.campaginsSub) this.campaginsSub.unsubscribe();
  }


}
