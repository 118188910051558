import { Component, Injectable, OnChanges, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/de';
import { UntypedFormGroup, FormControl } from '@angular/forms';

const I18N_VALUES = {
  'fr': {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
    weekLabel: 'sem'
  },
  'de': {
    weekdays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
    months: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    weekLabel: 'Wo'
  },
  'en': {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    weekLabel: 'Week'
  }
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  constructor(private translate: TranslateService){}
  
  language = this.translate.currentLang;
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) { super(); }

  getWeekdayLabel(weekday: number): string { return I18N_VALUES[this._i18n.language].weekdays[weekday - 1]; }
  getWeekLabel(): string { return I18N_VALUES[this._i18n.language].weekLabel; }
  getMonthShortName(month: number): string { return I18N_VALUES[this._i18n.language].months[month - 1]; }
  getMonthFullName(month: number): string { return this.getMonthShortName(month); }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
  getWeekdayShortName(weekday: number): string { return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];}
}

@Component({
  selector: 'ngbd-datepicker-i18n',
  templateUrl: './datepicker-i18n.html',
  styleUrls: ['./datepicker-i18n.scss'],
  encapsulation: ViewEncapsulation.None,
  providers:
      [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]  // define custom NgbDatepickerI18n provider
})

export class NgbdDatepickerI18n implements OnChanges{
    model: NgbDateStruct;
    lang: string;
    minDateNext: any;

    @Input()
    selectedDate: any;

    @Input()
    minDate: any;

    @Input()
    Inline: boolean;

    @Input()
    form: UntypedFormGroup;

    @Input()
    fieldname: string;
  
    @Output()
    onChange: EventEmitter<Date> = new EventEmitter()

    constructor(private translate: TranslateService,
      private dateparser: NgbDateParserFormatter){
    }

    public changes(event){
        this.lang = this.translate.currentLang;
        let localLocale = moment().year(event.year).month(event.month-1).date(event.day);
        this.onChange.emit(localLocale.toDate());
        localLocale.locale(this.lang);
        this.form?.controls[this.fieldname]?.setValue(localLocale.format('L'));
        this.form?.controls[this.fieldname]?.markAsTouched();
    }

    ngOnChanges(changes){
      let dt : Date 
      if(this.selectedDate){
        if(this.selectedDate instanceof Date){
          dt = this.selectedDate;
        }else{
          dt = this.selectedDate.toDate();
        }
  
        this.lang = this.translate.currentLang;
        let d = moment(dt.getTime());
        d.locale(this.lang);
        this.form?.controls[this.fieldname]?.setValue(d.format('L'));
      }

      let dtmin : Date;
      if(this.minDate){
        if(this.minDate instanceof Date){
          dtmin = this.minDate;
        }else{
          dtmin = this.minDate.toDate();
        }

        this.minDateNext = this.dateparser.parse(dtmin.toISOString());
      }
    }
}
