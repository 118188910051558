import { Component, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CampaignService, Campaign } from '../../shared/services/campaigns.service';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Customer, CustomerService } from '../../shared/services/customer.service';
import { ProductService, Product } from '../../shared/services/product.service';
import { DatatableOptions } from '../../shared/components/datatable/datatable.component';
import { Coupngroup, CoupngroupService } from '../../shared/services/coupngroup.service';
import { CoupncodeService, Coupn } from '../../shared/services/coupncode.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { CustomError } from '../../../../functions/src/interfaces/error.interface';
import { ErrorService } from '../../shared/services/error.service';


@Component({
  selector: 'app-campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: ['./campaign-view.component.scss']
})

export class CampaignViewComponent {

  campaign_form: UntypedFormGroup;
  campaign: Campaign;
  customer: Customer;
  products: Product[];
  coupngroups: Coupngroup[];

  codes: Coupn[];

  routeSub: Subscription;
  campSub: Subscription;
  customerSub: Subscription;
  coupngroupSub: Subscription;
  productSub: Subscription;
  codeSub: Subscription;

  eventEmitters : EventEmitter<any>[] = new Array();
  eventEmitters_coupns : EventEmitter<any>[] = new Array();
  datatableOptions: DatatableOptions;
  datatableOptions_coupns: DatatableOptions;
  deleteElement: Subscription;
  deleteElement_coupns: Subscription;
  clickElement: Subscription;
  clickElement_coupns: Subscription;

  assigned: number = 0;
  codesCreated: boolean = false;

  fnction;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private campaignService: CampaignService,
    private coupngroupService: CoupngroupService,
    private productService: ProductService,
    private coupncodeService: CoupncodeService,
    private fnc: AngularFireFunctions,
    private errorService: ErrorService
    ){
  }

  calculateAssigned(){
    let na: number = 0;
    this.coupngroups.forEach((cg) => {
      if(cg.cgrp_state == 1) na+= cg.cgrp_qty; 
    });
    this.assigned = na;
  }

  ngOnInit(): void {
    this.campaign_form = new UntypedFormGroup({});
    this.fnction = this.fnc.httpsCallable('writeCodesBulk');

    this.routeSub = this.activatedRoute.params.subscribe((par) => {
      if(par.id){
        this.campSub = this.campaignService.getCampaign(par.id).subscribe((campaign_data) => {
          this.campaign = campaign_data;

          if(this.datatableOptions_coupns) this.datatableOptions_coupns.max = this.campaign.camp_numberOfCoupns;
          if(this.customerSub) this.customerSub.unsubscribe();
          this.customerSub = this.customerService.getCustomer(this.campaign.camp_customer).subscribe((customer) => {
            this.customer = customer;
          })

          if(this.productSub) this.productSub.unsubscribe();
          this.productSub = this.productService.getAllProducts(this.campaign.camp_id).subscribe((products) => {
            this.products = products;
          })

          if(this.coupngroupSub) this.coupngroupSub.unsubscribe();
          this.coupngroupSub = this.coupngroupService.getAllCoupngroups(this.campaign.camp_id).subscribe((coupngroups) => {
            this.coupngroups = coupngroups;
            this.calculateAssigned();
          })

          if(this.codeSub) this.codeSub.unsubscribe();
          this.codeSub = this.coupncodeService.getAllCoupncodes(this.campaign.camp_id).subscribe((data) => {
            this.codes = data;
          })
        })
      }
    })


    this.datatableOptions = {
      classes: {
        header: {
          lineHeight: "2em",
          classes: [
            ['mw-125', 'd-flex'], 
            ['mw-100', 'd-none', 'd-sm-flex'], 
            ['mw-50', 'd-none', 'd-sm-flex', 'justify-content-end'], 
            ['mw-75', 'd-none', 'd-sm-flex'], 
          ],
          counts: {
            xs: {items:1 , widths: [100]},
            sm: {items:3 , widths: [25, 50, 10, 15]},
            md: {items:3 , widths: [25, 50, 10, 15]},
            lg: {items:5 , widths: [25, 50, 10, 15]},
            xl: {items:5 , widths: [25, 50, 10, 15]}
          }
        },
        row: {
          lineHeight: "4em",
          classes: [
            ['text-bold-500', 'mw-125', 'd-flex', 'font-small-2'], 
            ['mw-100', 'd-none', 'd-sm-flex', 'font-small-2'], 
            ['mw-50', 'd-none', 'd-sm-flex', 'font-small-2', 'justify-content-end', 'text-right'], 
            ['mw-75', 'd-none', 'd-sm-flex', 'font-small-2'], 
          ]
        }
      },
      fields: ['prod_name', 'prod_desc' ,'prod_qty', 'prod_state'],
      actions: [
        {id: "delete", title: "campaigns.pages.removeCampaign", icon: "fa-sharp fa-solid fa-trash"}
      ],
      click: [{
        name: 'prod_name',
        event: 'clickRow'
      }],
      filter: ['prod_name', 'prod_desc'],
      search: true
    }

    this.datatableOptions_coupns = {
      classes: {
        header: {
          lineHeight: "2em",
          classes: [
            ['mw-125', 'd-flex'], 
            ['mw-100', 'd-none', 'd-sm-flex'], 
            ['mw-50', 'd-none', 'd-sm-flex'], 
            ['mw-75', 'd-none', 'd-sm-flex'], 
          ],
          counts: {
            xs: {items:1 , widths: [100]},
            sm: {items:3 , widths: [30, 10, 45, 15]},
            md: {items:3 , widths: [30, 10, 45, 15]},
            lg: {items:5 , widths: [30, 10, 45, 15]},
            xl: {items:5 , widths: [30, 10, 45, 15]}
          }
        },
        row: {
          lineHeight: "4em",
          classes: [
            ['text-bold-500', 'mw-125', 'd-flex', 'font-small-2'], 
            ['mw-100', 'd-none', 'd-sm-flex', 'font-small-2'], 
            ['mw-50', 'd-none', 'd-sm-flex', 'font-small-2'], 
            ['mw-75', 'd-none', 'd-sm-flex', 'font-small-2'], 
          ]
        }
      },
      max: this.campaign?.camp_numberOfCoupns ? this.campaign.camp_numberOfCoupns : 0,
      fields: ['cgrp_name', 'cgrp_prefix' ,'cgrp_qty', 'cgrp_state'],
      actions: [
        {id: "delete", title: "coupns.pages.removeCoupn", icon: "fa-sharp fa-solid fa-trash"}
      ],
      click: [{
        name: 'cgrp_name',
        event: 'clickRow'
      }],
      filter: ['cgrp_name', 'cgrp_prefix'],
      search: true
    }

    this.datatableOptions?.actions?.forEach(action => {
      let event = new EventEmitter();
      this.eventEmitters[action.id] = event;
    });

    this.datatableOptions_coupns?.actions?.forEach(action => {
      let event = new EventEmitter();
      this.eventEmitters_coupns[action.id] = event;
    });

    this.eventEmitters['clickRow'] = new EventEmitter();
    this.eventEmitters_coupns['clickRow'] = new EventEmitter();

    this.deleteElement = this.eventEmitters['delete'].subscribe((data) => {
      this.productService.saveProduct(data, this.campaign.camp_id, true);
    })

    this.deleteElement_coupns = this.eventEmitters_coupns['delete'].subscribe((data) => {
      this.coupngroupService.saveCoupngroup(data, this.campaign.camp_id, true);
    })

    this.clickElement = this.eventEmitters['clickRow'].subscribe((data :any) => {
      this.router.navigate(['/campaigns/view',this.campaign.camp_id, data.prod_id ]);
    })

    this.clickElement_coupns = this.eventEmitters_coupns['clickRow'].subscribe((data :any) => {
      this.router.navigate(['/campaigns/view',this.campaign.camp_id, 'cpngrp', data.cgrp_id ]);
    })

  }

  generateCodesToCoupngroup(coupngroup: Coupngroup){
    this.fnction({campaign_id: this.campaign.camp_id, coupngroup: coupngroup}).subscribe((data :CustomError) => {
      this.errorService.error = data;
      this.errorService.popToast();
    })
  }

  getCodeAmounts(coupngroup_id: string) :number{
    let tmpcode = this.codes;
    return tmpcode.filter((x) => x.coup_group == coupngroup_id).length;
  }

  getProductCodesAmount(product_id: string) :number{
    let tmpcode = this.codes;
    return tmpcode.filter((x) => x.coup_product == product_id).length;
  }

  getProductCodesAmountRedeemed(product_id: string) :number{
    let tmpcode = this.codes;
    return tmpcode.filter((x) => x.coup_product == product_id && x.coup_redeemed == true).length;
  }

  getCodeAmountsGroupProduct(coupngroup_id: string, product_id: string) :number{
    let tmpcode = this.codes;
    return tmpcode.filter((x) => x.coup_group == coupngroup_id && x.coup_product == product_id).length;
  }

  checkCoupnGroupFull(coupngroup: Coupngroup)  :boolean{
    let tmpcode = this.codes;
    return tmpcode.filter((x) => x.coup_group == coupngroup.cgrp_id).length >= coupngroup.cgrp_qty ? true : false;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.customerSub) this.customerSub.unsubscribe();
    if(this.productSub) this.productSub.unsubscribe();
    if(this.coupngroupSub) this.coupngroupSub.unsubscribe();
    if(this.routeSub) this.routeSub.unsubscribe();
    if(this.campSub) this.campSub.unsubscribe();
    if(this.productSub) this.productSub.unsubscribe();
    if(this.deleteElement) this.deleteElement.unsubscribe();
    if(this.clickElement) this.clickElement.unsubscribe();
    if(this.codeSub) this.codeSub.unsubscribe();
  }

}
