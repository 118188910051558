<div class="d-flex align-items-center justify-content-between mt-3" *ngIf="campaign && coupngroup">
    <div class="d-flex align-items-center">
        <span class="font-small-2 text-bold-500 cursor-pointer" [routerLink]="['/campaigns/overview']">{{'campaigns.title' | translate | uppercase}}</span>
        <span class="fa-regular font-small-2 fa-chevron-right mx-2"></span>
        <span class="font-small-2 text-bold-500 cursor-pointer" [routerLink]="['/campaigns/view', campaign.camp_id]">{{campaign.camp_name | uppercase}}</span>
        <span class="fa-regular font-small-2 fa-chevron-right mx-2"></span>
        <span class="font-small-2 text-bold-500 cursor-pointer" [routerLink]="['/campaigns/view', campaign.camp_id, coupngroup.cgrp_id]">{{coupngroup.cgrp_name | uppercase}}</span>
    </div>
    <div class="d-flex">
        
    </div>
</div>
<div class="row mt-3" *ngIf="campaign">
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <div class="d-block" style="min-width: 35em;">
                <div class="d-flex justify-content-start">
                    <div class="d-flex">
                        <span class="font-small-2 cursor-pointer" [routerLink]="['/campaigns/view', campaign.camp_id]"><span class="fa-light fa-arrow-left-long mr-2"></span>{{'products.pages.backtocampaign' | translate}}</span>
                    </div>
                </div>
                <div class="d-flex card mt-1">
                    <div class="card-header">
                        <span class="font-medium-1 text-bold-500" style="letter-spacing: 0.1em;">{{'coupngroups.pages.newCoupngroup' | translate}}</span>
                    </div>
                    <div class="card-body">
                        <div class="d-block">
                            <app-field [fieldname]="'cgrp_name'" [form]="this.coupngroup_form"></app-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <div class="d-block" style="min-width: 35em;">
                <div class="d-flex card mt-1">
                    <div class="card-header">
                        <span class="font-medium-1 text-bold-500" style="letter-spacing: 0.1em;">{{'coupngroups.pages.CoupnQuantity' | translate}}</span>
                    </div>
                    <div class="card-body">
                        <div class="d-block">
                            <app-field [fieldname]="'cgrp_qty'" [fieldvalue]="[0]" [slideroptions]="{min: 0, max: this.campaign?.camp_numberOfCoupns, step: 1}" [form]="this.coupngroup_form"></app-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <div class="d-block" style="min-width: 35em;">
                <div class="d-flex card  mt-0">
                    <div class="card-header">
                        <span class="font-medium-1 text-bold-500" style="letter-spacing: 0.1em;">{{'coupngroups.pages.codeDisplays' | translate}}</span>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <div class="d-block pr-2">
                                    <app-field [fieldname]="'cgrp_prefix'" [form]="this.coupngroup_form" style="width: 15em;"></app-field>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="d-block pl-2">
                                    <app-field [fieldname]="'cgrp_separator'" [form]="this.coupngroup_form" style="width: 15em;"></app-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <app-field [fieldname]="'cgrp_state'" [simple]="true" [fieldvalue]="true" [form]="this.coupngroup_form"></app-field>
                    </div>
                    <div class="d-flex">
                        <button class="btn btn-sm btn-primary p-1 px-3 font-small-2" 
                        (click)="saveCoupngroup()"
                        [disabled]="this.coupngroup_form.invalid"
                    >{{'coupngroups.pages.saveCoupnGroup' | translate}}<span class="fa-light fa-arrow-right-long ml-2"></span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-none"><app-field [fieldname]="'cgrp_id'" [form]="this.coupngroup_form"></app-field></div>