import { Component, Input } from '@angular/core';
import { Product } from '../../shared/services/product.service';
import { FormGroup } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/functions';
import { CustomError } from '../../../../functions/src/interfaces/error.interface';
import { ErrorService } from '../../shared/services/error.service';
import { CoupncodeService } from '../../shared/services/coupncode.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-products-assign',
  templateUrl: './products-assign.component.html',
  styleUrls: ['./products-assign.component.scss']
})

export class ProductAssignComponent {

  @Input() product :Product;
  @Input() cgrpid: string;
  @Input() campid: string;

  product_assignmentForm: FormGroup;

  coupnSub: Subscription;
  value: number = 0;

  fnction;

  constructor(private errorService: ErrorService,
    private coupncodeService: CoupncodeService,
    private fnc :AngularFireFunctions){
  }

  ngOnInit(): void {
    this.product_assignmentForm = new FormGroup({})
    this.fnction = this.fnc.httpsCallable('assignProductsBulk');

    if(this.coupnSub) this.coupnSub.unsubscribe();
    this.coupnSub = this.coupncodeService.getAllProductCoupncodesByGroup(this.campid, this.cgrpid, this.product.prod_id).subscribe((data) => {
      this.value = data.length;
    })
  }

  assignProducts(){
    let amountToGenerate = this.product_assignmentForm.controls.prod_assignment.value;
    this.fnction({campaign_id: this.campid, coupngroup_id: this.cgrpid, productid: this.product.prod_id, amount: amountToGenerate}).subscribe((data :CustomError) => {
      this.errorService.error = data;
      this.errorService.popToast();
    })
  }

  ngOnDestroy(): void {
    if(this.coupnSub) this.coupnSub.unsubscribe();
  }


}
