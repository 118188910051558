<div class="d-flex align-items-center justify-content-between mt-3" *ngIf="campaign">
    <div class="d-flex align-items-center">
        <span class="font-small-2 text-bold-500 cursor-pointer" [routerLink]="['/campaigns/overview']">{{'campaigns.title' | translate | uppercase}}</span>
        <span class="fa-regular font-small-2 fa-chevron-right mx-2"></span>
        <span class="font-small-2 text-bold-500 cursor-pointer" [routerLink]="['/campaigns/view', campaign.camp_id]">{{campaign.camp_name | uppercase}}</span>
        <span class="fa-regular font-small-2 fa-chevron-right mx-2"></span>
        <span class="font-small-2 text-bold-500 cursor-pointer" [routerLink]="['/campaigns/view', campaign.camp_id, 'new']">{{'products.pages.new' | translate | uppercase}}</span>
    </div>
    <div class="d-flex">
        
    </div>
</div>
<div class="row mt-3" *ngIf="campaign">
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <div class="d-block" style="min-width: 35em;">
                <div class="d-flex justify-content-start">
                    <div class="d-flex">
                        <span class="font-small-2 cursor-pointer" [routerLink]="['/campaigns/view', campaign.camp_id]"><span class="fa-light fa-arrow-left-long mr-2"></span>{{'products.pages.backtocampaign' | translate}}</span>
                    </div>
                </div>
                <div class="d-flex card mt-1">
                    <div class="card-header">
                        <span class="font-medium-1 text-bold-500" style="letter-spacing: 0.1em;">{{'products.pages.newProduct' | translate}}</span>
                    </div>
                    <div class="card-body">
                        <div class="d-block">
                            <app-field [fieldname]="'prod_name'" [form]="this.product_form"></app-field>
                        </div>
                        <div class="d-block">
                            <app-field [fieldname]="'prod_qty'" [form]="this.product_form"></app-field>
                        </div>
                        <div class="d-block">
                            <app-field [fieldname]="'prod_desc'" [form]="this.product_form"></app-field>
                        </div>
                        <div class="d-block">
                            <app-field [fieldname]="'prod_isConnexProd'" [form]="this.product_form"></app-field>
                        </div>
                        <div class="d-block">
                            <app-field [fieldname]="'prod_extArtnNumber'" [form]="this.product_form"></app-field>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <app-field [fieldname]="'prod_state'" [simple]="true" [fieldvalue]="true" [form]="this.product_form"></app-field>
                    </div>
                    <div class="d-flex">
                        <button class="btn btn-sm btn-primary p-1 px-3 font-small-2" 
                        (click)="saveProduct()"
                        [disabled]="this.product_form.invalid"
                    >{{'products.pages.createProduct' | translate}}<span class="fa-light fa-arrow-right-long ml-2"></span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>