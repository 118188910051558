import { Component, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, FormControl } from '@angular/forms';
import { CampaignService, Campaign } from '../../shared/services/campaigns.service';
import { Subscription } from 'rxjs';
import { CoupngroupService, Coupngroup } from '../../shared/services/coupngroup.service';


@Component({
  selector: 'app-coupngroup-edit',
  templateUrl: './coupngroup-edit.component.html',
  styleUrls: ['./coupngroup-edit.component.scss']
})

export class CoupngroupEditComponent {

  coupngroup_form: UntypedFormGroup;
  
  routeSub: Subscription;
  campaignSub: Subscription;
  coupngroupSub: Subscription;

  campaign_id: string;
  coupngroup_id: string;

  campaign: Campaign;
  coupngroup: Coupngroup;

  constructor(private router: Router,
    private coupngroupService: CoupngroupService,
    private campaignService: CampaignService,
    private route: ActivatedRoute
    ){
  }

  ngOnInit(): void {
    this.coupngroup_form = new UntypedFormGroup({});
    this.routeSub = this.route.params.subscribe((par) => {
      this.campaign_id = par.campaign_id;
      this.campaignSub = this.campaignService.getCampaign(this.campaign_id).subscribe((campaign) => {
        this.campaign = campaign;
      })

      this.coupngroup_id = par.coupngroup_id;
      this.coupngroupSub = this.coupngroupService.getCoupngroup(this.campaign_id, this.coupngroup_id).subscribe((coupngroup) => {
        this.coupngroup = coupngroup;
        this.coupngroup_form.patchValue(coupngroup);
      })
    })
  }

  saveCoupngroup(){
    let new_Coupngroup = this.coupngroup_form.value;
    new_Coupngroup.cgrp_state = this.coupngroup_form.controls.cgrp_state.value == true ? 1 : 0;
    this.coupngroupService.saveCoupngroup(new_Coupngroup, this.campaign_id).then((result) => {
      this.router.navigate(['/campaigns/view', this.campaign.camp_id])
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.routeSub) this.routeSub.unsubscribe();
    if(this.campaignSub) this.campaignSub.unsubscribe();
    if(this.coupngroupSub) this.coupngroupSub.unsubscribe();
  }


}
