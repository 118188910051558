import { CustomError } from "../interfaces/error.interface";

export const severity = ['success','info','warning','error','critical'];

export var errors :CustomError[] = 
[
    {
        severity: severity[0],
        message: 'Errors.ERR_000000000',
        timestamp: new Date(),
        id: 'ERR_000000000'
    },
    {
        severity: severity[2],
        message: 'Errors.ERR_000000001',
        timestamp: new Date(),
        id: 'ERR_000000001'
    },
    {
        severity: severity[0],
        message: 'Errors.ERR_000000002',
        timestamp: new Date(),
        id: 'ERR_000000002'
    },
    {
        severity: severity[2],
        message: 'Errors.ERR_000000003',
        timestamp: new Date(),
        id: 'ERR_000000003'
    },
    {
        severity: severity[0],
        message: 'Errors.ERR_000000004',
        timestamp: new Date(),
        id: 'ERR_000000004'
    },
    {
        severity: severity[2],
        message: 'Errors.ERR_000000005',
        timestamp: new Date(),
        id: 'ERR_000000005'
    },
    {
        severity: severity[0],
        message: 'Errors.ERR_000000006',
        timestamp: new Date(),
        id: 'ERR_000000006'
    },
    {
        severity: severity[2],
        message: 'Errors.ERR_000000007',
        timestamp: new Date(),
        id: 'ERR_000000007'
    },
    {
        severity: severity[0],
        message: 'Errors.ERR_000000008',
        timestamp: new Date(),
        id: 'ERR_000000008'
    },
    {
        severity: severity[2],
        message: 'Errors.ERR_000000009',
        timestamp: new Date(),
        id: 'ERR_000000009'
    },
    {
        severity: severity[0],
        message: 'Errors.ERR_000000011',
        timestamp: new Date(),
        id: 'ERR_000000011'
    },
    {
        severity: severity[2],
        message: 'Errors.ERR_000000012',
        timestamp: new Date(),
        id: 'ERR_000000012'
    },
    {
        severity: severity[0],
        message: 'Errors.ERR_000000013',
        timestamp: new Date(),
        id: 'ERR_000000013'
    },
    {
        severity: severity[2],
        message: 'Errors.ERR_000000014',
        timestamp: new Date(),
        id: 'ERR_000000014'
    },
    {
        severity: severity[2],
        message: 'Errors.ERR_000000015',
        timestamp: new Date(),
        id: 'ERR_000000015'
    },
    {
        //Generating codes successful
        severity: severity[0],
        message: 'Errors.ERR_000000016',
        timestamp: new Date(),
        id: 'ERR_000000016'
    },
    {
        //No Params given
        severity: severity[2],
        message: 'Errors.ERR_000000017',
        timestamp: new Date(),
        id: 'ERR_000000017'
    },
    {
        //Not enough free coupons
        severity: severity[2],
        message: 'Errors.ERR_000000018',
        timestamp: new Date(),
        id: 'ERR_000000018'
    },
    {
        //Success asssigning products
        severity: severity[0],
        message: 'Errors.ERR_000000019',
        timestamp: new Date(),
        id: 'ERR_000000019'
    },
    {
        //Not enough Params set
        severity: severity[2],
        message: 'Errors.ERR_000000020',
        timestamp: new Date(),
        id: 'ERR_000000020'
    },
    {
        //No Code found
        severity: severity[3],
        message: 'Errors.ERR_000000021',
        timestamp: new Date(),
        id: 'ERR_000000021'
    },
    {
        //No Prize to this Coupn
        severity: severity[0],
        message: 'Errors.ERR_000000022',
        timestamp: new Date(),
        id: 'ERR_000000022'
    },
    {
        //Prize in Coupn but NOT Found in Prizelist
        severity: severity[3],
        message: 'Errors.ERR_000000023',
        timestamp: new Date(),
        id: 'ERR_000000023'
    },
    {
        //Congratulations you won
        severity: severity[0],
        message: 'Errors.ERR_000000024',
        timestamp: new Date(),
        id: 'ERR_000000024'
    },
    {
        //Coupn already redeemed
        severity: severity[2],
        message: 'Errors.ERR_000000025',
        timestamp: new Date(),
        id: 'ERR_000000025'
    },
    {
        //Redeem Coupn
        severity: severity[0],
        message: 'Errors.ERR_000000026',
        timestamp: new Date(),
        id: 'ERR_000000026'
    },
    {
        //Product Sent
        severity: severity[0],
        message: 'Errors.ERR_000000027',
        timestamp: new Date(),
        id: 'ERR_000000027'
    },
    {
        //Issues sending Product, contact Connex
        severity: severity[3],
        message: 'Errors.ERR_000000028',
        timestamp: new Date(),
        id: 'ERR_000000028'
    },
    {
        //No Email Given for digital transport
        severity: severity[3],
        message: 'Errors.ERR_000000029',
        timestamp: new Date(),
        id: 'ERR_000000029'
    },
    {
        //No foreign_Product ID given 
        severity: severity[3],
        message: 'Errors.ERR_000000030',
        timestamp: new Date(),
        id: 'ERR_000000030'
    }
]

export function findError(id: string, customObject?: object) :CustomError | undefined{
    let e = errors.find(e => e.id == id);
    if(e){
        e.timestamp = new Date();
        e.customErrorObject = customObject;
    }
    return e;
}