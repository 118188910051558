<div class="d-flex align-items-center justify-content-between mt-3">
    <div class="d-flex align-items-center">
        <span class="font-small-2 text-bold-500 cursor-pointer" [routerLink]="['/campaigns/overview']">{{'campaigns.title' | translate | uppercase}}</span>
        <span class="fa-regular font-small-2 fa-chevron-right mx-2"></span>
        <span class="font-small-2 text-bold-3 cursor-pointer" [routerLink]="['/campaigns/overview']">{{'campaigns.pages.overview' | translate | uppercase}}</span>
    </div>
    <div class="d-flex">
        <div class="btn btn-sm btn-primary p-1 px-3 font-small-2" [routerLink]="['/campaigns/new']"><span class="fa-light fa-plus mr-2"></span>{{'campaigns.pages.addCampaign' | translate}}</div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-12">
        <app-datatable [data]="this.data" [options]="this.datatableOptions" [events]="eventEmitters"></app-datatable>
    </div>
</div>