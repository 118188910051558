<div class="d-flex align-items-center justify-content-between mt-3">
    <div class="d-flex align-items-center">
        <span class="font-small-2 text-bold-500 cursor-pointer" [routerLink]="['/campaigns/overview']">{{'campaigns.title' | translate | uppercase}}</span>
        <span class="fa-regular font-small-2 fa-chevron-right mx-2"></span>
        <span class="font-small-2 text-bold-3 cursor-pointer" [routerLink]="['/campaigns', campaign_id]">{{campaign_name | uppercase}}</span>
    </div>
    <div class="d-flex">
        
    </div>
</div>
<div class="row mt-3">
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <div class="d-block" style="min-width: 35em;">
                <div class="d-flex justify-content-start">
                    <div class="d-flex">
                        <span class="font-small-2 cursor-pointer" [routerLink]="['/campaigns/overview']"><span class="fa-light fa-arrow-left-long mr-2"></span>{{'customers.pages.backtooverview' | translate}}</span>
                    </div>
                </div>
                <div class="d-flex card mt-1">
                    <div class="card-header">
                        <span class="font-medium-1 text-bold-500" style="letter-spacing: 0.1em;">{{'campaigns.pages.campaignInformation' | translate}}</span>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex" style="width: 100%">
                                <div class="d-block" style="width: 100%">
                                    <app-field [fieldname]="'camp_name'" [form]="this.campaign_form"></app-field>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <div class="d-block pr-2">
                                    <app-field [fieldname]="'camp_customer'" [form]="this.campaign_form" style="width: 15em;"></app-field>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="d-block pl-2">
                                    <app-field [fieldname]="'camp_state'" [form]="this.campaign_form" style="width: 15em;"></app-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <div class="d-block" style="min-width: 35em;">
                <div class="d-flex card  mt-0">
                    <div class="card-header">
                        <span class="font-medium-1 text-bold-500" style="letter-spacing: 0.1em;">{{'campaigns.pages.campaignCoupnNumber' | translate}}</span>
                    </div>
                    <div class="card-body">
                        <div class="d-block pr-2">
                            <app-field [fieldname]="'camp_numberOfCoupns'" [form]="this.campaign_form"></app-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <div class="d-block" style="min-width: 35em;">
                <div class="d-flex card  mt-0">
                    <div class="card-header">
                        <span class="font-medium-1 text-bold-500" style="letter-spacing: 0.1em;">{{'campaigns.pages.campaignRuntime' | translate}}</span>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <div class="d-block pr-2">
                                    <app-field [fieldname]="'camp_start'" [form]="this.campaign_form" style="width: 15em;"></app-field>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="d-block pl-2">
                                    <app-field [fieldname]="'camp_end'" [form]="this.campaign_form" style="width: 15em;"></app-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <div class="d-block" style="min-width: 35em;">
                <div class="d-flex card mt-1">
                    <div class="card-header">
                        <span class="font-medium-1 text-bold-500" style="letter-spacing: 0.1em;">{{'campaigns.pages.landingPageDomain' | translate}}</span>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex" style="width: 100%">
                                <div class="d-block" style="width: 100%">
                                    <app-field [fieldname]="'camp_domainLp'" [form]="this.campaign_form"></app-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <div class="d-flex">
                        <button class="btn btn-sm btn-primary p-1 px-3 font-small-2" 
                        (click)="saveCampaign()"
                        [disabled]="this.campaign_form.invalid"
                    >{{'campaigns.pages.saveCampaign' | translate}}<span class="fa-light fa-arrow-right-long ml-2"></span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-none">
    <app-field [fieldname]="'camp_id'" [form]="this.campaign_form"></app-field>
</div>