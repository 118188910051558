import { Injectable, OnDestroy } from '@angular/core';
import * as uuid from 'uuid'
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { ErrorService } from './error.service';
import { Country, countryListAllIsoData } from '../../../../functions/src/configs/country.configs';

@Injectable({
  providedIn: 'root'
})

export class CoupngroupService implements OnDestroy{
    //temporary NotificationObject
    coupngroup: Coupngroup;

    //Subscriptions
    coupnSub: Subscription;

    //collectiondef
    collection: string = "campaigns";
    subcollection: string = "coupngroups";

    constructor(
        private fs: AngularFirestore,
        private errorservice: ErrorService
        ) 
    { 
        
    }
    /** 
     * @param campaign_id id of the Campaign
     * @param id id of the Coupngroup 
     * @returns Coupngroup as Observable
    */
    getCoupngroup(campaign_id: string, id: string) :Observable<any>{
        return this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection).doc(id).valueChanges();
    }

    /** 
     * @param campaign_id id of the Campaign
     * @returns AllCoupngroups Observable
    */
    getAllCoupngroups(campaign_id: string) :Observable<any>{
        return this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection , col => col.where('cgrp_state', '!=', 2)).valueChanges();
    }

    /** 
     * @param campaign_id id of the Campaign
     * @returns AllCoupngroups as Promise
    */
    getPromiseCoupngroups(campaign_id: string) :Promise<Coupngroup[]>{
        return new Promise<Coupngroup[]>((resolve) => {
            let sub = this.getAllCoupngroups(campaign_id).subscribe((sc) => {
                resolve(sc);
                if(sub){
                    sub.unsubscribe();
                }
            })
        })
    }

    /** 
     * @param sc Coupngroup Object
     * @param campaign_id id of the Campaign
     * @returns Promise of type Error
    */
    saveCoupngroup(sc: Coupngroup, campaign_id: string, del?:boolean) :Promise<any>{
        return new Promise<any>((resolve) => {
            let document = this.fs.collection(this.collection).doc(campaign_id).collection(this.subcollection);

            document.doc(sc.cgrp_id).get().toPromise().then((doc => {
                sc.cgrp_updated = new Date();
                if(!doc.exists){
                    sc.cgrp_id = uuid.v4();
                    sc.cgrp_created = new Date();
                    document.doc(sc.cgrp_id).set(sc).then((success) => {
                        this.errorservice.getError("ERR_000000012").then((error) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    }).catch((error) => {
                        this.errorservice.getError("ERR_000000013").then((error) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    });
                }else{
                    let success = "ERR_000000012";
                    let error = "ERR_000000013";
                    if(del){
                        sc.cgrp_state = 2;
                        success = "ERR_000000014";
                        error = "ERR_000000015"
                    }
                    document.doc(sc.cgrp_id).update(sc).then((s) => {
                        this.errorservice.getError(success).then((e) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    }).catch((er) => {
                        this.errorservice.getError(error).then((e) => {
                            this.errorservice.popToast();
                            resolve(this.errorservice.error) 
                        })
                    });
                }
            }))
        })
    }
    
    getPromiseCountries() :Promise<Country[]>{
        return new Promise<Country[]>((resolve) => {
                resolve(countryListAllIsoData);
        })
    }

    ngOnDestroy(): void {

    }
}

export interface Coupngroup{
    cgrp_id: string,
    cgrp_name: string,
    cgrp_qty?: number,
    cgrp_prefix?: string,
    cgrp_separator?: string,
    cgrp_state: number,
    cgrp_created: any,
    cgrp_updated?: any,
}