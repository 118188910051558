import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, ViewEncapsulation, ViewChild, ElementRef, HostListener, AfterViewInit, AfterViewChecked, AfterContentChecked } from '@angular/core';
import * as moment from 'moment';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { CampaignService } from 'app/shared/services/campaigns.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { spinnerconfig_inline, spinnerconfig_box_white } from '../../configs/spinner.config';
import { CustomerService } from 'app/shared/services/customer.service';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FieldComponent implements OnInit, OnChanges, AfterContentChecked{

  @ViewChild("fieldtext") fieldtext: ElementRef;
  @ViewChild("fieldicon") fieldicon: ElementRef;
  @ViewChild("fielddate") fielddate: ElementRef;
  @ViewChild("fieldheading") fieldheading: ElementRef;

  @HostListener("window:resize", ['$event'])
  onResize(event?){
      if(this.fieldheading?.nativeElement?.scrollWidth > this.fieldheading?.nativeElement?.clientWidth
        || this.fielddate?.nativeElement?.scrollWidth > this.fielddate?.nativeElement?.clientWidth
        || this.fieldicon?.nativeElement?.scrollWidth > this.fieldicon?.nativeElement?.clientWidth
        || this.fieldtext?.nativeElement?.scrollWidth > this.fieldtext?.nativeElement?.clientWidth){
        this.popdisabled = false;
      }else{
        this.popdisabled = true;
      }
  }

  @Input() fieldname: string;
  @Input() fieldvalue: any;
  @Input() fieldDescription: boolean;
  @Input() simple: boolean;
  @Input() slideroptions: {min?: number, max?: number, step?: number, disabled?: boolean};
  @Input() form: UntypedFormGroup;

  @Output() valueFormatted = new EventEmitter<any>();

  isField: string;
  isInForm: boolean;
  formControlName: string;
  popdisabled: boolean;
  value: any;
  max: number = 100;
  options: any;
  items:any;
  spinning: boolean = true;
  fields :Field[];
  formSub: Subscription;

  constructor(private campaignService: CampaignService,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService
    ) {
    this.isInForm = false;
    this.popdisabled = true;
    
  }

  public toFullTime(number: number){
    return number.toLocaleString('de-DE', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
  }

  private renderText(data: string){
    return data;
  }

  private renderCurrency(data: number){
    return data;
  }

  private renderTime(data: {day: number, hour: number, minute: number, second: number}){
    let val = "< ";
    val += (data.day !== 0 && data.day !== undefined) ? data.day.toString()+'d ' : '';
    val += data.hour !== 0 ? data.hour.toString()+'h ' : '';
    val += data.minute !== 0 ? data.minute.toString()+'m ' : '';
    return val;
  }

  private renderSelect(data: string){
    return new Promise<any>((resolve) => {
      let values:any;
      this.options?.function.then(dt => {
        values = dt.find(x => x[this.options?.displayfield.substr(0,4)+'_id'] == data);
        if(values?.length > 0){
          values = values[0];
        }
        resolve(values !== undefined ? values[this.options?.displayfield] : undefined);
      })
    })
  }

  private renderToggle(data: boolean){
      return data;
  }

  private renderDateTime(data: any){
    if(moment.isDate(data)){
      return moment(data).format("DD.MM.YYYY, HH:mm");
    }else{
      if(data.toDate){
        return moment(data.toDate()).format("DD.MM.YYYY, HH:mm");
      }else{
        return data;
      }
    }
  }

  private watchFormChanges() {
    if(this.formSub) this.formSub.unsubscribe();
    this.formSub = this.form.get(this.fieldname).valueChanges
      .pipe(debounceTime(150))
      .subscribe(v => {
        this.form.get(this.fieldname).setValue(v, { onlySelf: true, emitEvent: false });
      });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.spinning = true;
    this.spinner.show("fieldspinner", spinnerconfig_inline);
    this.spinner.show('taskspinner', spinnerconfig_box_white);
    setTimeout(() => {
      this.spinner.hide('taskspinner');
      this.spinning = false;
    }, 350)
  }

  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    if(this.fieldheading?.nativeElement?.scrollWidth > this.fieldheading?.nativeElement?.clientWidth
      || this.fielddate?.nativeElement?.scrollWidth > this.fielddate?.nativeElement?.clientWidth
      || this.fieldicon?.nativeElement?.scrollWidth > this.fieldicon?.nativeElement?.clientWidth
      || this.fieldtext?.nativeElement?.scrollWidth > this.fieldtext?.nativeElement?.clientWidth){
      this.popdisabled = false;
    }else{
      this.popdisabled = true;
    }
  }

  private initFields(){
    this.fields = [
      {fieldname: "camp_id", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "camp_name", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "camp_customer", type: "select", function: this.renderSelect, validators: [Validators.required], options: {function: this.customerService.getPromiseCustomers(), displayfield: "cust_name"}},
      {fieldname: "camp_start", type: "date", function: this.renderDateTime, options: {simple: this.simple}},
      {fieldname: "camp_end", type: "date", function: this.renderDateTime, options: {simple: this.simple}},
      {fieldname: "camp_domainLp", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "camp_numberOfCoupns", type: "number", function: this.renderText, validators: [Validators.minLength(1)], options: {simple: this.simple} },
      {fieldname: "camp_created", type: "date", function: this.renderDateTime, options: {simple: this.simple}},
      {fieldname: "camp_updated", type: "date", function: this.renderDateTime, options: {simple: this.simple}},
      {fieldname: "camp_state", type: "toggle", function: this.renderToggle, options: {simple: this.simple}},
      {fieldname: "cust_id", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "cust_name", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "cust_zip", type: "number", function: this.renderText, validators: [Validators.minLength(4)], options: {simple: this.simple} },
      {fieldname: "cust_place", type: "text", function: this.renderText, options: {simple: this.simple}},
      {fieldname: "cust_street", type: "text", function: this.renderText, options: {simple: this.simple}},
      {fieldname: "cust_created", type: "date", function: this.renderDateTime, options: {simple: this.simple}},
      {fieldname: "cust_updated", type: "date", function: this.renderDateTime, options: {simple: this.simple}},
      {fieldname: "cust_state", type: "toggle", function: this.renderToggle, options: {simple: this.simple}},
      {fieldname: "cust_contactId", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "cust_contactName", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "cust_contactEmail", type: "text", function: this.renderText, validators: [Validators.email], options: {simple: this.simple}},
      {fieldname: "cust_contactPhone", type: "text", function: this.renderText, validators: [], options: {simple: this.simple}},
      {fieldname: "cust_country", type: "select", function: this.renderSelect,validators: [], options: {function: this.customerService.getPromiseCountries(), displayfield: "coun_name"}},
      {fieldname: "prod_id", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "prod_name", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "prod_qty", type: "number", function: this.renderText, validators: [Validators.minLength(1)], options: {simple: this.simple} },
      {fieldname: "prod_desc", type: "textarea", function: this.renderText, options: {simple: this.simple}},
      {fieldname: "prod_state", type: "toggle", function: this.renderToggle, options: {simple: this.simple}},
      {fieldname: "prod_isConnexProd", type: "toggle", function: this.renderToggle, options: {simple: this.simple}},
      {fieldname: "prod_extArtnNumber", type: "text", function: this.renderText, validators: [], options: {simple: this.simple}},
      {fieldname: "prod_created", type: "date", function: this.renderDateTime, options: {simple: this.simple}},
      {fieldname: "prod_updated", type: "date", function: this.renderDateTime, options: {simple: this.simple}},
      {fieldname: "cgrp_id", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "cgrp_name", type: "text", function: this.renderText, validators: [Validators.required], options: {simple: this.simple}},
      {fieldname: "cgrp_qty", type: "slider", function: this.renderText, validators: [Validators.minLength(1)], options: {simple: this.simple}},
      {fieldname: "cgrp_prefix", type: "text", function: this.renderText, options: {simple: this.simple}},
      {fieldname: "cgrp_separator", type: "text", function: this.renderText, options: {simple: this.simple}},
      {fieldname: "cgrp_state", type: "toggle", function: this.renderToggle, options: {simple: this.simple}},
      {fieldname: "cgrp_created", type: "date", function: this.renderDateTime, options: {simple: this.simple}},
      {fieldname: "cgrp_updated", type: "date", function: this.renderDateTime, options: {simple: this.simple}},
      {fieldname: "cgrp_assigned", type: "slider", function: this.renderText, validators: [Validators.minLength(1)], options: {simple: this.simple}},
      {fieldname: "prod_assignment", type: "slider", function: this.renderText, validators: [Validators.minLength(1)], options: {simple: this.simple}},
    ]
  }

  ngOnChanges(changes): void {
    this.initFields();

    if(this.fieldname && this.fieldvalue !== undefined){
      this.isField = this.fields.find(f => f.fieldname == this.fieldname).type;
      this.options = this.fields?.find(f => f.fieldname == this.fieldname).options;
      if(!this.fieldDescription){
        if(this.isField !== 'select' && this.isField !== 'serviceclass'){
          this.value = this.fields.find(f => f.fieldname == this.fieldname).function(this.fieldvalue);
        }else{
          this.fields.find(f => f.fieldname == this.fieldname).function(this.fieldvalue).then((val) => {
            this.value = val
          });
        }
      }
      this.valueFormatted.emit(this.value);
    }
    
    if(this.form && this.fieldname){
      if(!this.form.controls[this.fieldname]){
        let val = this.fields.find(f => f.fieldname == this.fieldname)?.validators;
        this.form.addControl(this.fieldname, new UntypedFormControl(this.fieldvalue ? this.fieldvalue : undefined, val));
        this.formControlName = this.fieldname;
        this.options = this.fields.find(f => f.fieldname == this.fieldname)?.options;
        if(this.isField == "slider"){
          this.watchFormChanges();
        }
      }else{
        this.form?.controls[this.formControlName]?.setValue(this.fieldvalue);
      }
      this.fields.find(f => f.fieldname == this.fieldname)?.options?.function?.then((data) => {
        this.items = data;
      })
      this.isField = this.fields.find(f => f.fieldname == this.fieldname)?.type;
      this.isInForm = true;
    }
  }
}

export interface Field {
  fieldname: string,
  type: string,
  function: any,
  validators?: Array<ValidatorFn>,
  options?: any
}
