import { Component, OnInit, OnDestroy, Input, OnChanges, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { isDate } from 'moment';
import { Subscription } from 'rxjs';
import { FieldComponent } from '../field/field.component';
import * as moment from 'moment';
import { DragulaService } from 'ng2-dragula';
import { CampaignService } from '../../services/campaigns.service';


@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DatatableComponent implements OnInit, OnChanges, OnDestroy{

  @Input() data: Array<any>
  @Input() options: DatatableOptions
  @Input() events: EventEmitter<any>[] = new Array();
  
  dataDisplay :any;
  searchForm: UntypedFormGroup;
  searchSubscription :Subscription;
  actualSorting: {field: string, type: string}

  constructor(
      private dragulaService: DragulaService
    ){
    this.options = {
      classes: {
        header: {
          lineHeight: "4em"
        },
        row: {
          lineHeight: "4em",
          classes: new Array()
        }
      },
      fields: new Array()
    }
  }

  getDatapoints(object){
    if(this.options?.fields?.length > 0){
      return Object.values(this.options?.fields);
    }else{
      return Object.keys(object);
    }
  }

  getClassListRow(index){
    if(this.options?.classes?.row?.classes){
      if(this.options?.classes?.row?.classes[index]){
        return this.options?.classes?.row?.classes[index];
      }else{
        return [];
      }
    }else{
      return [];
    }
  }

  getClassListHeader(index){
    if(this.options?.classes?.header?.classes){
      if(this.options?.classes?.header?.classes[index]){
        return this.options?.classes?.header?.classes[index];
      }else{
        return [];
      }
    }else{
      return [];
    }
  }

  getType(object:any){
    return typeof object;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.searchForm = new UntypedFormGroup(
      {search: new UntypedFormControl("")}
    );

    if(this.options?.filter){
      this.searchSubscription = this.searchForm.valueChanges.subscribe((filter) => {
        let dataconsolidated = new Array();
        this.data.forEach((elem) => {
          let searchstring: string = "";
          let vals = Object.values(elem);
          let keys = Object.keys(elem);
          keys.forEach((field,index) => {
            if(this.options?.filter?.find(f => f == field)){
              if(typeof vals[index] == 'object'){
                let o = Object.values(vals[index]).map((value) => {
                  if(moment.isDate(value)){
                    return moment(value).format("DD.MM.YYYY");
                  }else if(value.toDate !== undefined){
                    return moment(value.toDate()).format("DD.MM.YYYY");
                  }else{
                    return value;
                  }
                })
                searchstring += o.join(' ');
              }else{
                searchstring += vals[index];
              }
              
            }
          });
          if(searchstring.toLowerCase().match(filter.search.toLowerCase())){
            dataconsolidated.push(elem);
          }
        })
        this.dataDisplay = dataconsolidated;
      })
    }

    let d = this.dragulaService.createGroup("tableRows", {
      moves: (el, container, handle) => {
        if(this.options?.reordering?.field){
          return handle.classList.contains('handle');
        }else{
          return false;
        }
      }
    });

    this.dragulaService.dropModel("tableRows").subscribe((data) => {
      let dt  = data.targetModel.map((mf, index) => {
        mf[this.options?.reordering?.field] = index;
        return mf;
      });

      this.emitEvent('dataChanged', dt);
    })

  }

  emitEvent(index, data: any) {
    this.events[index].emit(data);
  }

  getTableColumns(index: number){
    if(this.options?.classes?.header?.counts){
      if(window.innerWidth <= 576 && this.options?.classes?.header?.counts?.xs){
        return this.options?.classes?.header?.counts?.xs?.widths[index];
      }else if(window.innerWidth <= 768 && this.options?.classes?.header?.counts?.sm){
        return this.options?.classes?.header?.counts?.sm?.widths[index];
      }else if(window.innerWidth <= 992 && this.options?.classes?.header?.counts?.md){
        return this.options?.classes?.header?.counts?.md?.widths[index];
      }else if(window.innerWidth <= 1200 && this.options?.classes?.header?.counts?.lg){
        return this.options?.classes?.header?.counts?.lg?.widths[index];
      }else{
        if(this.options?.classes?.header?.counts?.xl){
          return this.options?.classes?.header?.counts?.xl?.widths[index];
        }
      }
    }

    if(this.options?.fields?.length > 0){
      return Object.values(this.options?.fields).length;
    }else{
      return Object.keys(this.data[0]).length;
    }
  }
  
  getEventClickable(fielname: string){
    let e = this.options?.click?.find((cl) => cl.name == fielname)?.event;
    return e ? e : undefined;
  }

  sortTable(fields: string, mode: string){
    if(!this.options?.sortDisabled){
      if(fields !== 'seav_availability' && fields !== 'sere_reactionTime'){
        this.data.sort((x,y) => {
          if(mode == 'fa-sort-amount-down'){
            return y[fields] < x[fields] ? -1 : 1
          }else if(mode == 'fa-sort-amount-up'){
            return y[fields] < x[fields] ? 1 : -1
          }
        });
    
        this.actualSorting = {
          field: fields,
          type: mode
        }
      }
    }
  }
  
  log(c){
    console.log(c);
  }

  getSortIcon(field: string){
    if(field == this.actualSorting?.field){
      return this.actualSorting.type;
    }else{
      return 'fa-sort-amount-up'
    }
  }

  ngOnChanges(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.data){
      if(this.options?.sort){
        this.sortTable(this.options?.sort, this.getSortIcon(this.options?.sort));
      }
      this.dataDisplay = this.data;
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.searchSubscription){
      this.searchSubscription.unsubscribe();
    }

    this.dragulaService.destroy('tableRows');
  }

}

export interface DatatableOptions{
  classes?: {
    header?: {
      lineHeight?: string,
      classes?: Array<any>,
      counts?: {
        xs?: {items: number, widths: number[]},
        sm?: {items: number, widths: number[]},
        md?: {items: number, widths: number[]},
        lg?: {items: number, widths: number[]},
        xl?: {items: number, widths: number[]}
      }
    }
    row?: {
      lineHeight?: string,
      classes?: Array<any>
    }
  }
  fields?: Array<any>,
  actions?: {
    id?: string,
    title?: string,
    icon?: string
  }[],
  click?: {
    name?: string,
    event?: string
  }[],
  max?: number,
  filter?: string[],
  sort?: string,
  sortDisabled?: boolean,
  buttons?: boolean,
  search?:boolean,
  reordering?: {
    field?: string,
    function?: any
  }
}
