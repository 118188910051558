import { Component, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { ProductService, Product } from '../../shared/services/product.service';
import { CampaignService, Campaign } from '../../shared/services/campaigns.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})

export class ProductEditComponent {

  product_form: UntypedFormGroup;
  
  routeSub: Subscription;
  campaignSub: Subscription;
  productSub: Subscription;

  campaign_id: string;
  product_id: string;
  campaign: Campaign;
  product: Product;

  constructor(private router: Router,
    private productService: ProductService,
    private campaignService: CampaignService,
    private route: ActivatedRoute
    ){
  }

  ngOnInit(): void {
    this.product_form = new UntypedFormGroup({});
    this.routeSub = this.route.params.subscribe((par) => {
      this.campaign_id = par.campaign_id;
      this.product_id = par.product_id;
      this.campaignSub = this.campaignService.getCampaign(this.campaign_id).subscribe((campaign) => {
        this.campaign = campaign;
      })
      this.productSub = this.productService.getProduct(this.campaign_id, this.product_id).subscribe((product) => {

        this.product = product;
        this.product_form.patchValue(this.product);
      })
    })
  }

  saveProduct(){
    let new_Product = this.product_form.value;
    new_Product.prod_state = this.product_form.controls.prod_state.value == true ? 1 : 0;
    this.productService.saveProduct(new_Product, this.campaign_id).then((result) => {
      this.router.navigate(['/campaigns/view', this.campaign.camp_id])
    })
  }


}
