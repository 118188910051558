<form [formGroup]="this.form" *ngIf="this.form" style="width: 100%">
    <fieldset class="form-group position-relative has-icon-right mb-0 pb-0" [ngClass]="Inline ? 'inline' : ''" *ngIf="this.form && this.fieldname">
        <input class="form-control" placeholder="" [formControlName]="this.fieldname" (click)="d.toggle()" [ngClass]="this.form.controls[this.fieldname].dirty && this.form?.controls[this.fieldname]?.errors ? ['border-danger'] : ['']">
        <input type="hidden" name="dp" ngbDatepicker #d="ngbDatepicker" (dateSelect)="changes($event)" [minDate]="minDateNext">
        <div class="form-control-position cursor-pointer">
            <span class="p-1" (click)="d.toggle()"><i class="fal fa-calendar"></i></span>
        </div>
    </fieldset>
</form>


